import React, { useContext } from "react"
import _ from "lodash"

import useTriggerDataLayer from "../../../../../hooks/useTriggerDataLayer"

import { CINRCtx } from "../context/cinrCtx"
import { useNavigate } from "react-router-dom"

import { Button } from "primereact/button"
import { MultiSelect } from "primereact/multiselect"
import { Dropdown } from "primereact/dropdown"
import { Skeleton } from "primereact/skeleton"

import SaveReport from "../../../components/SaveReport"

import styles from "../../../../../styles/DataRoom.module.css"

export default function SubFilterBCCategories() {
  const context = useContext(CINRCtx)
  const [triggerDataLayer] = useTriggerDataLayer()
  const navigate = useNavigate()

  const handleChange = (e) => {
    context.resetPayload()
    const { id, value } = e.target

    const segment = context.selectedValues.segment

    const segmentMap = {
      segment_value: (value = null) => {
        if (value) {
          return value
        }
        switch (segment) {
          case "carrier_name": {
            return context.selectedValues.carrier_name
          }
          case "facility_system_primary": {
            return context.selectedValues.facility_system_primary
          }
          case "facility_provider_type": {
            return context.selectedValues.facility_provider_type
          }
          default: {
            return null
          }
        }
      },
      segment_value_name: (value) => {
        if (value) {
          return value
        }
        switch (segment) {
          case "carrier_name": {
            return context.selectedValues.carrier_plan_name
          }
          case "facility_system_primary": {
            return context.selectedValues.hco_provider_organization_name
          }
          case "facility_provider_type": {
            return context.selectedValues.carrier_plan_name
          }
          default: {
            return null
          }
        }
      },
    }

    const valuesFromParams = {
      segment,
      billing_code_category: context.selectedValues.billing_code_category,
      billing_code_category_value:
        context.selectedValues.billing_code_category_value,
      region: context.selectedValues.region,
      region_value: context.selectedValues.region_value,
      visualization: context.selectedValues.visualization,
    }

    const handleStateValues = () => {
      if (context.selectedValues.region === "state_counties") {
        return context.selectedValues.county_values
      }
      if (context.selectedValues.region === "state_cbsa_msa") {
        return context.selectedValues.cbsa_msa_values
      }
      return null
    }

    switch (id) {
      case "visualization": {
        context.setLoadingField("billing_code_type_label")
        context.fetchOptions("billing_code_type_label", {
          ...valuesFromParams,
          segment_value: segmentMap.segment_value(),
          segment_value_name: segmentMap.segment_value_name(),
          billing_code_episode: context.selectedValues.billing_code_episode,
          procedure_type: context.selectedValues.procedure_type,
          negotiated_type: context.selectedValues.negotiated_type,
          state_values: handleStateValues(),
          visualization: value,
        })
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          [id]: value,
        }))

        break
      }
      case "county_values":
      case "cbsa_msa_values": {
        const reset = {
          billing_code_episode: null,
          carrier_name: null,
          carrier_plan_name: null,
          facility_system_primary: null,
          facility_provider_type: null,
          procedure_type: null,
          negotiated_type: null,
          billing_code_type_label: null,
          hco_provider_organization_name: null,
        }
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        context.setLoadingField("billing_code_episode")
        context.fetchOptions("billing_code_episode", {
          ...valuesFromParams,
          state_values: value,
        })
        break
      }

      case "billing_code_episode": {
        const reset = {
          carrier_name: null,
          carrier_plan_name: null,
          facility_system_primary: null,
          facility_provider_type: null,
          procedure_type: null,
          negotiated_type: null,
          billing_code_type_label: null,
          hco_provider_organization_name: null,
          state_values: handleStateValues(),
        }
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))

        if (context.selectedValues.segment === "facility_system_primary") {
          context.setLoadingField("facility_system_primary")
          context.fetchOptions("facility_system_primary", {
            ...valuesFromParams,
            state_values: handleStateValues(),
            billing_code_episode: value,
          })
          break
        }

        if (context.selectedValues.segment === "facility_provider_type") {
          context.setLoadingField("facility_provider_type")
          context.fetchOptions("facility_provider_type", {
            ...valuesFromParams,
            billing_code_episode: value,
          })
          break
        }

        context.setLoadingField("carrier_name")
        context.fetchOptions("carrier_name", {
          ...valuesFromParams,
          state_values: handleStateValues(),
          billing_code_episode: value,
        })
        break
      }
      case "carrier_name": {
        const reset = {
          carrier_plan_name: null,
          procedure_type: null,
          negotiated_type: null,
          billing_code_type_label: null,
        }
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        context.setLoadingField("carrier_plan_name")
        context.fetchOptions("carrier_plan_name", {
          ...valuesFromParams,
          state_values: handleStateValues(),
          segment_value: value,
        })
        break
      }
      case "carrier_plan_name": {
        const reset = {
          procedure_type: null,
          negotiated_type: null,
          billing_code_type_label: null,
        }
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        context.setLoadingField("procedure_type")
        context.fetchOptions("procedure_type", {
          ...valuesFromParams,
          segment_value: segmentMap.segment_value(),
          segment_value_name: segmentMap.segment_value_name(value),
          state_values: handleStateValues(),
          billing_code_episode: context.selectedValues.billing_code_episode,
        })

        break
      }
      case "procedure_type": {
        const reset = {
          negotiated_type: null,
          billing_code_type_label: null,
        }
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        context.setLoadingField("negotiated_type")
        context.fetchOptions("negotiated_type", {
          ...valuesFromParams,
          segment_value: segmentMap.segment_value(),
          segment_value_name: segmentMap.segment_value_name(),
          state_values: handleStateValues(),
          billing_code_episode: context.selectedValues.billing_code_episode,
          procedure_type: value,
        })
        break
      }
      case "negotiated_type": {
        const reset = {
          billing_code_type_label: null,
        }
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        if (context.selectedValues.visualization === "grouped-bar") {
          context.setLoadingField("billing_code_type_label")
          context.fetchOptions("billing_code_type_label", {
            ...valuesFromParams,
            segment_value: segmentMap.segment_value(),
            segment_value_name: segmentMap.segment_value_name(),
            state_values: handleStateValues(),
            billing_code_episode: context.selectedValues.billing_code_episode,
            procedure_type: context.selectedValues.procedure_type,
            negotiated_type: value,
          })
        }
        break
      }
      case "facility_provider_type": {
        const reset = {
          carrier_plan_name: null,
          procedure_type: null,
          negotiated_type: null,
        }
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        context.setLoadingField("carrier_plan_name")
        context.fetchOptions("carrier_plan_name", {
          ...valuesFromParams,
          state_values: handleStateValues(),
          segment_value: segmentMap.segment_value(value),
          billing_code_episode: context.selectedValues.billing_code_episode,
        })
        break
      }
      case "facility_system_primary": {
        const reset = {
          hco_provider_organization_name: null,
          procedure_type: null,
          negotiated_type: null,
        }
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))

        context.setLoadingField("hco_provider_organization_name")
        context.fetchOptions("hco_provider_organization_name", {
          ...valuesFromParams,
          segment_value: segmentMap.segment_value(value), // Check
          segment_value_name: segmentMap.segment_value_name(),
          state_values: handleStateValues(),
          billing_code_episode: context.selectedValues.billing_code_episode,
        })
        break
      }
      case "hco_provider_organization_name": {
        const reset = {
          hco_provider_organization_name: null,
          procedure_type: null,
          negotiated_type: null,
        }
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        context.setLoadingField("procedure_type")
        context.fetchOptions("procedure_type", {
          ...valuesFromParams,
          segment_value: segmentMap.segment_value(),
          segment_value_name: segmentMap.segment_value_name(value),
          billing_code_episode: context.selectedValues.billing_code_episode,
          state_values: handleStateValues(),
        })
        break
      }
      default:
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          [id]: value,
        }))
        break
    }
  }

  const HandleDropdown = ({ id, label }) => {
    if (context.loadingFields.includes(id)) {
      return (
        <div>
          <label>{label}</label>
          <Skeleton width="100%" height="43px"></Skeleton>
        </div>
      )
    }
    return (
      <div>
        <label>{label}</label>
        <Dropdown
          options={context.options[id]}
          value={context.selectedValues[id]}
          onChange={handleChange}
          placeholder={label}
          id={id}
          disabled={_.isEmpty(context.options[id])}
        />
      </div>
    )
  }

  const HandleMultiSelect = ({ id, label }) => {
    if (context.loadingFields.includes(id)) {
      return (
        <div>
          <label>{label}</label>
          <Skeleton width="100%" height="3rem"></Skeleton>
        </div>
      )
    }
    if (id === "billing_code_type_label") {
      return (
        <div>
          <label>{label}</label>
          <MultiSelect
            options={context.options[id]}
            value={context.selectedValues[id]}
            onChange={handleChange}
            placeholder={label}
            filter
            showClear
            showSelectAll={false}
            selectionLimit={context.maxOpBillingCodeTypeLabel}
            selectAll={false}
            id={id}
          />
          <small>
            Maximum number of selections: {context.maxOpBillingCodeTypeLabel}
          </small>
        </div>
      )
    }
    return (
      <div>
        <label>{label}</label>
        <MultiSelect
          options={context.options[id]}
          value={context.selectedValues[id]}
          onChange={handleChange}
          placeholder={label}
          filter
          showClear
          id={id}
        />
      </div>
    )
  }

  const handleTopFilters = () => {
    const region = context?.selectedValues?.region || null
    switch (region) {
      case "state_counties": {
        return (
          <div className={styles.subFilters}>
            <HandleMultiSelect id="county_values" label="County Selection" />
          </div>
        )
      }
      case "state_cbsa_msa": {
        return (
          <div className={styles.subFilters}>
            <HandleMultiSelect
              id="cbsa_msa_values"
              label="CBSA-MSA Selection"
            />
          </div>
        )
      }
      default:
        return null
    }
  }

  const HandleCommercialInsurance = () => {
    if (context.selectedValues.segment === "carrier_name") {
      return (
        <div className={styles.subFilters}>
          <HandleMultiSelect
            id="carrier_name"
            label="Commercial Insurance Carrier"
          />
          <HandleMultiSelect
            id="carrier_plan_name"
            label="Commercial Insurance Plan Name"
          />
        </div>
      )
    }
  }

  const HandleFacilityProviderSys = () => {
    if (context.selectedValues.segment === "facility_system_primary") {
      return (
        <div className={styles.subFilters}>
          <HandleMultiSelect
            id="facility_system_primary"
            label="Facility Provider System"
          />
          <HandleMultiSelect
            id="hco_provider_organization_name"
            label="Facility Provider Name"
          />
        </div>
      )
    }
  }

  const HandleFacilityProviderType = () => {
    if (context.selectedValues.segment === "facility_provider_type") {
      return (
        <div className={styles.subFilters}>
          <HandleMultiSelect
            id="facility_provider_type"
            label="Facility Provider Type"
          />
          <HandleMultiSelect
            id="carrier_plan_name"
            label="Commercial Insurance Plan Name"
          />
        </div>
      )
    }
  }

  return (
    <div className={styles.subFiltersWrapper}>
      <div className={styles.subFilters}>
        <HandleDropdown id="visualization" label="Plot Display" />
      </div>

      {handleTopFilters()}

      <div className={styles.subFilters}>
        <HandleMultiSelect id="billing_code_episode" label="Episode Name" />
      </div>

      <HandleCommercialInsurance />
      <HandleFacilityProviderSys />
      <HandleFacilityProviderType />

      <div className={styles.subFilters}>
        <HandleDropdown id="procedure_type" label="Procedure Type" />
        <HandleDropdown id="negotiated_type" label="Negotiated Rate Type" />
      </div>

      {context.selectedValues.visualization === "grouped-bar" && (
        <div className={styles.subFilters}>
          <HandleMultiSelect
            id="billing_code_type_label"
            label="Billing Codes"
          />
        </div>
      )}

      <Button
        label="Visualize"
        onClick={() => {
          const dataLayerBody = {
            report: "Analytic Dashboard",
            screen: 2,
            ...context.selectedValues,
          }
          triggerDataLayer("data_visualize", dataLayerBody)
          context.handleGenerate()
        }}
        className={context.isValidate ? "btn-secondary" : "btn-secondary grey"}
        disabled={!context.isValidate}
      />
      <Button
        label="Reset"
        onClick={() => navigate("/data-room/")}
        className="reset-btn"
        disabled={!context.isValidate}
      />
      <SaveReport
        isValidate={context.isValidate}
        selectedValues={context.selectedValues}
        reportName={context.reportName}
        projectName={context.projectName}
        saveReport={context.handleSaveReport}
      />
    </div>
  )
}
