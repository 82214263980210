import React, { useEffect, useState, useContext } from "react"
import { UserContext } from "../../../context/userContext"

import _ from "lodash"
import { useNavigate } from "react-router-dom"

import axios from "axios"

import { Dropdown } from "primereact/dropdown"
import { MultiSelect } from "primereact/multiselect"
import { Button } from "primereact/button"

// helper
import objectToUrlParams from "../../../helpers/objectToUrlParams"

import styles from "../../../styles/DataRoom.module.css"

// DataRoom URL
import { dataRoomURL } from "../../../config/cloudFunctionsURL"

const baseUrl = dataRoomURL()

const DEF_FIELDS = [
  {
    id: "cbsa_msa_name",
    label: "Region Type",
    endpoint: `${baseUrl}/api/selectors/region-values-price-rate`,
    required: true,
    initialFetch: true,
    multiselect: false,
  },
  {
    id: "region_value",
    label: "Region Selection",
    endpoint: `${baseUrl}/api/selectors/region-price-rate`,
    required: true,
    initialFetch: false,
    multiselect: false,
  },
  {
    id: "segment",
    label: "View Rates By",
    endpoint: `${baseUrl}/api/selectors/segments-price-rate`,
    required: true,
    initialFetch: false,
    multiselect: false,
  },
  {
    id: "billing_code_category",
    label: "Clinical Categories Type",
    endpoint: `${baseUrl}/api/selectors/clinical-category-price-rate`,
    required: true,
    initialFetch: false,
    multiselect: false,
  },
  {
    id: "billing_code_category_value",
    label: "Clinical Categories",
    endpoint: `${baseUrl}/api/selectors/clinical-category-value-price-rate`,
    required: true,
    initialFetch: false,
    multiselect: false,
  },
]

const FilterDropdown = (props) => {
  const { label, id, selectedValue, options, onChange, fetching, multiselect } =
    props
  const isEmpty = _.isEmpty(selectedValue)
  const handleIcon = () => {
    if (fetching) {
      return (
        <span className={`material-icons ${styles.labelIco}`}>
          hourglass_top
        </span>
      )
    }
    return !isEmpty ? (
      <span className={`material-icons ${styles.labelIco}`}>task_alt</span>
    ) : (
      <span className={`material-icons ${styles.labelIco}`}>touch_app</span>
    )
  }
  const ico = handleIcon()

  const handleDropdownType = () => {
    if (multiselect) {
      return (
        <MultiSelect
          key={id}
          id={id}
          placeholder={label}
          value={selectedValue}
          options={options}
          onChange={(e) => onChange(e.target.id, e.target.value)}
          disabled={fetching}
        />
      )
    }
    return (
      <Dropdown
        key={id}
        id={id}
        value={selectedValue}
        options={options}
        onChange={(e) => onChange(id, e.value)}
        placeholder={`Select ${label}`}
        disabled={fetching}
      />
    )
  }
  return (
    <div className={styles.filter}>
      <label>
        {ico} {label}
      </label>
      {handleDropdownType()}
    </div>
  )
}

const FilterContainer = () => {
  const navigate = useNavigate()
  const userCtx = useContext(UserContext)

  // State default values
  const initialOptions = _.reduce(
    DEF_FIELDS,
    (acc, field) => {
      acc[field.id] = []
      return acc
    },
    {}
  )
  const initialValues = _.reduce(
    DEF_FIELDS,
    (acc, field) => {
      acc[field.id] = null
      return acc
    },
    {}
  )

  const [options, setOptions] = useState(initialOptions)
  const [loadingFields, setLoadingFields] = useState([])
  const [selectedValues, setSelectedValues] = useState(initialValues)

  useEffect(() => {
    const initialFetch = async () => {
      const initialFetches = _.filter(DEF_FIELDS, { initialFetch: true })
      return await Promise.all(
        _.map(initialFetches, (field) => fetchOptions(field.id))
      )
    }
    initialFetch()
  }, [])

  const fetchOptions = async (filterType, postBody = {}) => {
    const field = _.find(DEF_FIELDS, { id: filterType })
    if (!field) {
      console.error("Invalid filter type:", filterType)
      return
    }
    const { id, endpoint } = field
    setLoadingField(id)
    try {
      const response = await axios.post(endpoint, {
        user_role: String(userCtx.user_role), // shoulb be string
        data_region: userCtx.data_region,
        ...postBody,
      })
      setOptions((prevOptions) => ({
        ...prevOptions,
        [id]: response.data.options,
      }))
      removeLoadingField(id)
    } catch (error) {
      console.error("Error fetching options:", error)
    }
  }

  const setLoadingField = (id) => {
    setLoadingFields((prev) => [...prev, id])
  }

  const removeLoadingField = (id) => {
    setLoadingFields((prev) => prev.filter((field) => field !== id))
  }

  const handleChange = (id, value) => {
    switch (id) {
      case "cbsa_msa_name": {
        const reset = initialValues
        setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        fetchOptions("region_value", { cbsa_msa_name: value })
        break
      }
      case "region_value": {
        const reset = {
          segment: null,
        }
        setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        fetchOptions("segment", {
          ...selectedValues,
          region_value: value,
        })
        break
      }
      case "segment": {
        setSelectedValues((prevValues) => ({
          ...prevValues,
          [id]: value,
        }))
        fetchOptions("billing_code_category", {
          ...selectedValues,
          segment: value,
        })
        break
      }
      case "billing_code_category": {
        const reset = {
          ...selectedValues,
          billing_code_category_value: null,
        }
        setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        fetchOptions("billing_code_category_value", {
          ...selectedValues,
          billing_code_category: value,
        })
        break
      }
      default:
        setSelectedValues((prevValues) => ({
          ...prevValues,
          [id]: value,
        }))
        break
    }
  }

  const handleClick = () => {
    const paramsString = objectToUrlParams(selectedValues)
    return navigate(
      `/data-room/price-rate-modeling/carriers-and-facility-systems?${paramsString}`
    )
  }

  const handleValidation = () => {
    return _.every(DEF_FIELDS, (field) => selectedValues[field.id])
  }

  const isValidate = handleValidation()

  const fields = _.map(DEF_FIELDS, ({ id, label, multiselect }) => ({
    id,
    label,
    multiselect,
  }))

  return (
    <div className={styles.initFilters}>
      {_.map(fields, (field) => {
        const { id, label, multiselect } = field

        return (
          <FilterDropdown
            key={id}
            id={id}
            label={label}
            options={options[id]}
            selectedValue={selectedValues[id]}
            onChange={handleChange}
            fetching={_.includes(loadingFields, id)}
            multiselect={multiselect}
          />
        )
      })}
      <Button
        label="Visualize"
        onClick={handleClick}
        disabled={!isValidate}
        className={isValidate ? "btn-secondary" : "btn-secondary grey"}
      />
    </div>
  )
}

export default FilterContainer
