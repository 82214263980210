import React, { useContext } from "react"
import _ from "lodash"
import { PMRCtx } from "../context/prmCtx"

import useTriggerDataLayer from "../../../../../hooks/useTriggerDataLayer"

import { useNavigate } from "react-router-dom"

import { Button } from "primereact/button"
import { MultiSelect } from "primereact/multiselect"
import { Dropdown } from "primereact/dropdown"
import { Skeleton } from "primereact/skeleton"

import SaveReport from "../../../components/SaveReport"

import styles from "../../../../../styles/DataRoom.module.css"

export default function SubFilterBCCategories() {
  const context = useContext(PMRCtx)
  const [triggerDataLayer] = useTriggerDataLayer()
  const navigate = useNavigate()

  const handleChange = (e) => {
    context.resetPayload()
    const { id, value } = e.target

    const segment = context.selectedValues.segment

    const segmentMap = {
      segment_value: () => {
        switch (segment) {
          case "carrier_name": {
            return context.selectedValues.carrier_plan_name
          }
          case "facility_system_primary": {
            return context.selectedValues.facility_system_primary
          }
          default: {
            return null
          }
        }
      },
    }

    const valuesFromParams = {
      segment,
      cbsa_msa_name: context.selectedValues.cbsa_msa_name,
      region_value: context.selectedValues.region_value,
      billing_code_category: context.selectedValues.billing_code_category,
      billing_code_category_value:
        context.selectedValues.billing_code_category_value,
    }

    switch (id) {
      case "billing_code_category_value": {
        const reset = {
          billing_code_episode: null,
          facility_system_primary: null,
          carrier_plan_name: null,
          procedure_type: null,
          negotiated_type: null,
        }
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        context.setLoadingField("billing_code_episode")
        context.fetchOptions("billing_code_episode", {
          ...valuesFromParams,
          billing_code_category_value: value,
        })
        break
      }
      case "billing_code_episode": {
        const reset = {
          facility_system_primary: null,
          carrier_plan_name: null,
          procedure_type: null,
          negotiated_type: null,
        }
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        if (context.selectedValues.segment === "facility_system_primary") {
          context.setLoadingField("facility_system_primary")
          context.fetchOptions("facility_system_primary", {
            ...valuesFromParams,
            billing_code_episode: value,
          })
          break
        }
        context.setLoadingField("carrier_plan_name")
        context.fetchOptions("carrier_plan_name", {
          ...valuesFromParams,
          billing_code_episode: value,
        })
        break
      }
      case "facility_system_primary": {
        const reset = {
          carrier_plan_name: null,
          procedure_type: null,
          negotiated_type: null,
        }
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        context.setLoadingField("procedure_type")
        context.fetchOptions("procedure_type", {
          ...valuesFromParams,
          segment_value: value,
          billing_code_episode: context.selectedValues.billing_code_episode,
        })
        break
      }
      case "carrier_plan_name": {
        const reset = {
          procedure_type: null,
          negotiated_type: null,
          billing_code_type_label: null,
        }
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        context.setLoadingField("procedure_type")
        context.fetchOptions("procedure_type", {
          ...valuesFromParams,
          segment_value: value,
        })

        break
      }
      case "procedure_type": {
        const reset = {
          negotiated_type: null,
        }
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        context.setLoadingField("negotiated_type")
        context.fetchOptions("negotiated_type", {
          ...valuesFromParams,
          segment_value: segmentMap.segment_value(),
          procedure_type: value,
        })
        break
      }
      default:
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          [id]: value,
        }))
        break
    }
  }

  const HandleDropdown = ({ id, label }) => {
    if (context.loadingFields.includes(id)) {
      return (
        <div>
          <label>{label}</label>
          <Skeleton width="100%" height="43px"></Skeleton>
        </div>
      )
    }
    return (
      <div>
        <label>{label}</label>
        <Dropdown
          options={context.options[id]}
          value={context.selectedValues[id]}
          onChange={handleChange}
          placeholder={label}
          id={id}
          disabled={_.isEmpty(context.options[id])}
        />
      </div>
    )
  }

  const HandleMultiSelect = ({ id, label }) => {
    if (context.loadingFields.includes(id)) {
      return (
        <div>
          <label>{label}</label>
          <Skeleton width="100%" height="3rem"></Skeleton>
        </div>
      )
    }
    if (id === "billing_code_type_label") {
      return (
        <div>
          <label>{label}</label>
          <MultiSelect
            options={context.options[id]}
            value={context.selectedValues[id]}
            onChange={handleChange}
            placeholder={label}
            filter
            showClear
            showSelectAll={false}
            selectionLimit={context.maxOpBillingCodeTypeLabel}
            selectAll={false}
            id={id}
          />
          <small>
            Maximum number of selections: {context.maxOpBillingCodeTypeLabel}
          </small>
        </div>
      )
    }
    return (
      <div>
        <label>{label}</label>
        <MultiSelect
          options={context.options[id]}
          value={context.selectedValues[id]}
          onChange={handleChange}
          placeholder={label}
          filter
          showClear
          id={id}
        />
      </div>
    )
  }

  const HandleCommercialInsurance = () => {
    if (context.selectedValues.segment === "carrier_name") {
      return (
        <div className={styles.subFilters}>
          <HandleMultiSelect
            id="carrier_plan_name"
            label="Commercial Insurance Plan Name"
          />
        </div>
      )
    }
  }

  const HandleFacilityProviderSys = () => {
    if (context.selectedValues.segment === "facility_system_primary") {
      return (
        <div className={styles.subFilters}>
          <HandleMultiSelect
            id="facility_system_primary"
            label="Facility Provider Name"
          />
        </div>
      )
    }
  }

  return (
    <div className={styles.subFiltersWrapper}>
      <div className={styles.subFilters}>
        <HandleDropdown
          id="billing_code_category_value"
          label="Clinical Categories"
        />
        <HandleMultiSelect id="billing_code_episode" label="Episode Name" />
      </div>

      <HandleCommercialInsurance />
      <HandleFacilityProviderSys />

      <div className={styles.subFilters}>
        <HandleDropdown id="procedure_type" label="Procedure Type" />
        <HandleDropdown id="negotiated_type" label="Negotiated Rate Type" />
      </div>

      {context.selectedValues.visualization === "grouped-bar" && (
        <div className={styles.subFilters}>
          <HandleMultiSelect
            id="billing_code_type_label"
            label="Billing Codes"
          />
        </div>
      )}

      <Button
        label="Visualize"
        onClick={() => {
          const dataLayerBody = {
            report: "Price Rate Modeling",
            screen: 2,
            ...context.selectedValues,
          }
          triggerDataLayer("data_visualize", dataLayerBody)
          context.handleGenerate()
        }}
        className={context.isValidate ? "btn-secondary" : "btn-secondary grey"}
        disabled={!context.isValidate}
      />
      <Button
        label="Reset"
        onClick={() => navigate("/data-room/")}
        className="reset-btn"
        disabled={!context.isValidate}
      />
      <SaveReport
        isValidate={context.isValidate}
        selectedValues={context.selectedValues}
        reportName={context.reportName}
        projectName={context.projectName}
        saveReport={context.handleSaveReport}
      />
    </div>
  )
}
