import React, { useState, useEffect, useContext } from "react"
import _ from "lodash"
import axios from "axios"
import { LoaderProgressBar } from "../../../../components/Loaders"

// Ctx
import { UserContext } from "../../../../context/userContext"
import { PMRCtx } from "./context/prmCtx"

// PR
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"

// Comps
import UserHelpText from "../../components/UserHelpText"
import Bar from "./highcharts/Bar"

// Filters
import SubFilter from "./components/SubFilter"

// DataRoom URL
import { dataRoomURL } from "../../../../config/cloudFunctionsURL"

import styles from "../../../../styles/DataRoom.module.css"

const baseUrl = dataRoomURL()

const DEF_FIELDS = [
  {
    id: "billing_code_episode",
    label: "Episode Name",
    endpoint: `${baseUrl}/api/selectors/episode-name-price-rate`,
    required: true,
    initialFetch: false,
  },
  {
    id: "facility_system_primary",
    label: "Commercial Insurance Carrier",
    endpoint: `${baseUrl}/api/selectors/segment-value-price-rate`,
    required: true,
    initialFetch: false,
  },
  {
    id: "carrier_plan_name",
    label: "Commercial Insurance Plan Name",
    endpoint: `${baseUrl}/api/selectors/segment-value-price-rate`,
    required: true,
    initialFetch: false,
  },
  {
    id: "procedure_type",
    label: "Negotiated Rate Type",
    endpoint: `${baseUrl}/api/selectors/procedure-type-price-rate`,
    required: true,
    initialFetch: false,
  },
  {
    id: "negotiated_type",
    label: "Negotiated Rate Type",
    endpoint: `${baseUrl}/api/selectors/negotiated-type-price-rate`,
    required: true,
    initialFetch: false,
  },
]

export default function CarriersAndFacilitySystems() {
  const userCtx = useContext(UserContext)

  const [loading, setLoading] = useState(false)
  const [fetching, setFetching] = useState(false)

  const [loadingFields, setLoadingFields] = useState([])

  const [payload, setPayload] = useState({})
  const [geomeandata, setGeomeandata] = useState([])

  const [maxOpBillingCodeTypeLabel, setMaxOpBillingCodeTypeLabel] = useState(8)

  const [reportId, setReportId] = useState("")
  const [projectName, setProjectName] = useState("")
  const [reportName, setReportName] = useState("")

  // Params from URL
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  // Default values
  const initialOptions = _.reduce(
    DEF_FIELDS,
    (acc, field) => {
      acc[field.id] = []
      return acc
    },
    {}
  )
  const initialValues = _.reduce(
    DEF_FIELDS,
    (acc, field) => {
      acc[field.id] = null
      return acc
    },
    {}
  )

  // URL Param Values
  const valuesFromUrl = {
    cbsa_msa_name: urlParams.get("cbsa_msa_name") ?? "",
    region_value: urlParams.get("region_value") ?? "",
    segment: urlParams.get("segment") ?? "",
    billing_code_category: urlParams.get("billing_code_category") ?? "",
    billing_code_category_value:
      urlParams.get("billing_code_category_value") ?? "",
    report_id: urlParams.get("report_id") ?? null,
  }

  const [selectedValues, setSelectedValues] = useState({
    ...initialValues,
    ...valuesFromUrl,
    visualization: "pricerate",
  })

  // Filters
  const [options, setOptions] = useState({
    ...initialOptions,
    visualization: [{ label: "Bar", value: "bar" }],
  })

  // Init
  useEffect(() => {
    init()
  }, [])

  const getStoredValues = async () => {
    const { report_id } = valuesFromUrl
    setReportId(report_id)
    const url = `${baseUrl}/api/reports/get-full-report`
    const response = await axios.post(url, {
      report_id,
    })
    const reportName = response.data.report_name || ""
    const projectName = response.data.project_name || ""
    setProjectName(projectName)
    setReportName(reportName)
    const values = response.data.values || {}
    return values
  }

  const init = async () => {
    setLoading(true)

    let selectedValuesToUse = {}
    const hadStoredValues = !_.isEmpty(valuesFromUrl.report_id)

    if (hadStoredValues) {
      await fetchInitialFilterValues()
      selectedValuesToUse = await getStoredValues()
      setSelectedValues((prevValues) => ({
        ...prevValues,
        ...selectedValuesToUse,
      }))
    } else {
      selectedValuesToUse = await fetchInitialFilterValues()
    }

    const generateBody = {
      ...selectedValues,
      ...selectedValuesToUse,
    }

    if (selectedValues.segment === "facility_system_primary") {
      generateBody.segment_value = generateBody.facility_system_primary
    }
    if (selectedValues.segment === "carrier_name") {
      generateBody.segment_value = generateBody.carrier_plan_name
    }

    return handleGenerate(generateBody)
  }

  const fetchInitialFilterValues = async () => {
    const url = `${baseUrl}/api/selectors/dash-d-filters-init`
    try {
      const response = await axios.post(url, {
        cbsa_msa_name: selectedValues.cbsa_msa_name,
        region_value: selectedValues.region_value,
        segment: selectedValues.segment,
        billing_code_category: selectedValues.billing_code_category,
        billing_code_category_value: selectedValues.billing_code_category_value,
      })
      const { defaultValues, options } = response.data
      setSelectedValues((prevValues) => ({
        ...prevValues,
        ...defaultValues,
      }))
      setOptions((prevOptions) => ({
        ...prevOptions,
        ...options,
      }))
      return defaultValues
    } catch (error) {
      console.error("Error fetching initial filter values", error)
    }
  }

  const fetchOptions = async (filterType, postBody = {}) => {
    const field = _.find(DEF_FIELDS, { id: filterType })
    if (!field) {
      console.error("Invalid filter type:", filterType)
      return
    }
    const { id, endpoint } = field
    setLoadingField(id)
    try {
      const response = await axios.post(endpoint, postBody)
      setOptions((prevOptions) => ({
        ...prevOptions,
        [id]: response.data.options,
      }))

      if (id === "billing_code_type_label") {
        setMaxOpBillingCodeTypeLabel(response.data.max_options)
        setSelectedValues((prevValues) => ({
          ...prevValues,
          ...response.data.defaultValues,
        }))
      }

      removeLoadingField(id)
    } catch (error) {
      console.error("Error fetching options:", error)
    }
  }

  const handleGenerate = async (postBody = {}) => {
    setLoading(true)

    if (_.isEmpty(postBody)) {
      postBody = selectedValues
      if (selectedValues.segment === "facility_system_primary") {
        postBody.segment_value = selectedValues.facility_system_primary
      }
      if (selectedValues.segment === "carrier_name") {
        postBody.segment_value = selectedValues.carrier_plan_name
      }
    }

    try {
      const fetchUrl = `${baseUrl}/api/dashboard/provider-price-rate-bar`
      const response = await axios.post(fetchUrl, postBody)

      setPayload(response.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const setLoadingField = (id) => {
    setLoadingFields((prev) => [...prev, id])
  }

  const removeLoadingField = (id) => {
    setLoadingFields((prev) => prev.filter((field) => field !== id))
  }

  const resetPayload = () => setPayload({})

  const handleSaveReport = async (reportName, projectName, permission) => {
    if (!reportName || !projectName) return

    setLoading(true)

    const currentUrl = new URL(window.location.href)
    const reportPath = currentUrl.pathname + currentUrl.search

    const reportSaveUrl = `${baseUrl}/api/reports/save-report`

    let postBody = {
      user_id: userCtx.id,
      user_role: String(userCtx.user_role),
      values: selectedValues,
      url: reportPath,
      visualization: selectedValues.visualization,
      organization_alias: userCtx.organization_alias,
      report_name: reportName,
      report_id: "",
      project_name: projectName,
      permission,
    }

    if (reportId) {
      postBody.report_id = reportId
    }

    if (selectedValues.segment === "facility_system_primary") {
      postBody.values.segment_value = selectedValues.facility_system_primary
      postBody.values.segment_value_name =
        selectedValues.hco_provider_organization_name
    } else if (selectedValues.segment === "facility_provider_type") {
      postBody.values.segment_value = selectedValues.facility_provider_type
      postBody.values.segment_value_name = selectedValues.carrier_plan_name
    } else if (selectedValues.segment === "carrier_name") {
      postBody.values.segment_value = selectedValues.carrier_name
      postBody.values.segment_value_name = selectedValues.carrier_plan_name
    }

    try {
      const response = await axios.post(reportSaveUrl, postBody)
      setReportId(response.data.doc_id)
      setLoading(false)
    } catch (error) {
      console.error("Error saving report", error)
    }
  }

  const validationPerType = () => {
    const requieredFieldsFacility = [
      "billing_code_category_value",
      "billing_code_episode",
      "facility_system_primary",
      "procedure_type",
      "negotiated_type",
    ]

    const requieredFieldsCarrier = [
      "billing_code_category_value",
      "billing_code_episode",
      "carrier_plan_name",
      "procedure_type",
      "negotiated_type",
    ]

    if (selectedValues.segment === "facility_system_primary") {
      return requieredFieldsFacility.every(
        (field) => !_.isEmpty(selectedValues[field])
      )
    }

    if (selectedValues.segment === "carrier_name") {
      return requieredFieldsCarrier.every(
        (field) => !_.isEmpty(selectedValues[field])
      )
    }
  }

  const ctxValue = {
    fetching,
    fetchOptions,
    options,
    setSelectedValues,
    selectedValues,
    maxOpBillingCodeTypeLabel,
    handleGenerate,
    resetPayload,
    handleSaveReport,
    reportName,
    projectName,
    loadingFields,
    setLoadingField,
    isValidate: validationPerType(),
  }

  const handleGeomeanTable = () => {
    if (_.isEmpty(geomeandata)) return null

    const groupedData = _.chain(geomeandata)
      .groupBy("billing_code_type_label")
      .map((codeData, code) => ({
        billingCodeTypeLabel: code,
        ..._.mapValues(
          _.keyBy(codeData, "carrier_name"),
          "geomean_negotiated_rate"
        ),
      }))
      .value()

    // Flattening the array of objects into an array of rows
    const dataTableArray = _.flatMap(groupedData, (row) => [row])

    const columns = Object.keys(dataTableArray[0]).map((column) => ({
      field: column,
      header: column,
    }))

    return (
      <DataTable value={dataTableArray}>
        {columns.map((col) => (
          <Column key={col.field} field={col.field} header={col.header} />
        ))}
      </DataTable>
    )
  }

  const handleChartPerType = () => {
    if (loading) return <LoaderProgressBar />
    if (_.isEmpty(payload)) return <UserHelpText />
    switch (selectedValues.visualization) {
      case "pricerate":
        return <Bar payload={payload} />
      default:
        return null
    }
  }

  // temp for 'ths_sandbox' organization
  const userOrg = userCtx?.organization_alias
  const handleHideText = () => {
    if (userOrg === "ths_sandbox") {
      return "hidden"
    }
    return ""
  }

  function HandleHeader() {
    return (
      <div>
        <h1>
          Price Rate Modeling: Projected Total Costs based on
          <span className={handleHideText()}> MMA</span> Regional Utilization
          Data
        </h1>
        <p>
          The dashboard displays comparative projected costs for category
          billing codes by commercial insurance carrier plans within a defined
          geographic Core-Based Statistical Area (CBSA). Loaded in this tool for
          modeling is a basket of top billing codes representing steady state
          utilization in a commercial population derived from{" "}
          <span className={handleHideText()}>MMA Region claims, </span>
          MarketScan Commercial Claims and Encounters Database, and the
          Healthcare Cost and Utilization Project.{" "}
          <span className={handleHideText()}>
            Projected costs are derived by multiplying a carrier average
            negotiated rate for a billing code by respective claim counts as
            reported by MMA.
          </span>
        </p>
        <p>
          In general, the chart data aims to inform price and cost modeling
          practices within set geographic regions. All reported billing codes
          reflect institutional class negotiated rates.
        </p>
      </div>
    )
  }

  return (
    <div className={`${styles.pageLayout} data-room`}>
      <HandleHeader />

      <div className={styles.wrapper}>
        <div className="filters">
          <PMRCtx.Provider value={ctxValue}>
            <SubFilter />
          </PMRCtx.Provider>
        </div>
        <div className={styles.plot}>
          {handleChartPerType()}
          {handleGeomeanTable()}
        </div>
      </div>
    </div>
  )
}
