import React, { useEffect, useState, useContext } from "react"
import { UserContext } from "../../../context/userContext"

import _ from "lodash"
import { useNavigate } from "react-router-dom"

import axios from "axios"

import { Dropdown } from "primereact/dropdown"
import { MultiSelect } from "primereact/multiselect"
import { Button } from "primereact/button"

// helper
import objectToUrlParams from "../../../helpers/objectToUrlParams"

import styles from "../../../styles/DataRoom.module.css"

// DataRoom URL
import { dataRoomURL } from "../../../config/cloudFunctionsURL"

const baseUrl = dataRoomURL()

const DEF_FIELDS = [
  {
    id: "geomap_view",
    label: "Geomap View",
    endpoint: `${baseUrl}/api/selectors/geomap-view`,
    required: true,
    initialFetch: true,
    multiselect: false,
  },
  {
    id: "npi_practice_state",
    label: "Region",
    endpoint: `${baseUrl}/api/selectors/region-map`,
    required: true,
    initialFetch: true,
    multiselect: false,
  },
  {
    id: "view_rates_by",
    label: "View Rates By",
    endpoint: `${baseUrl}/api/selectors/view-rates-by`,
    required: true,
    initialFetch: false,
    multiselect: false,
  },
  {
    id: "map_layer",
    label: "Map Layer",
    endpoint: `${baseUrl}/api/selectors/map-layer`,
    required: true,
    initialFetch: false,
    multiselect: false,
  },
  {
    id: "billing_code_category",
    label: "Clinical Categories Type",
    endpoint: `${baseUrl}/api/selectors/billing-code-category`,
    required: false,
    initialFetch: false,
    multiselect: true,
  },
]

const FilterDropdown = (props) => {
  const { label, id, selectedValue, options, onChange, fetching, multiselect } =
    props
  const isEmpty = _.isEmpty(selectedValue)
  const handleIcon = () => {
    if (fetching) {
      return (
        <span className={`material-icons ${styles.labelIco}`}>
          hourglass_top
        </span>
      )
    }
    return !isEmpty ? (
      <span className={`material-icons ${styles.labelIco}`}>task_alt</span>
    ) : (
      <span className={`material-icons ${styles.labelIco}`}>touch_app</span>
    )
  }
  const ico = handleIcon()

  const handleDropdownType = () => {
    if (multiselect) {
      return (
        <MultiSelect
          key={id}
          id={id}
          placeholder={label}
          value={selectedValue}
          options={options}
          onChange={(e) => onChange(e.target.id, e.target.value)}
          disabled={fetching}
        />
      )
    }
    return (
      <Dropdown
        key={id}
        id={id}
        value={selectedValue}
        options={options}
        onChange={(e) => onChange(id, e.value)}
        placeholder={`Select ${label}`}
        disabled={fetching}
      />
    )
  }
  return (
    <div className={styles.filter}>
      <label>
        {ico} {label}
      </label>
      {handleDropdownType()}
    </div>
  )
}

const FilterContainer = () => {
  const userCtx = useContext(UserContext)
  const navigate = useNavigate()

  // State default values
  const initialOptions = _.reduce(
    DEF_FIELDS,
    (acc, field) => {
      acc[field.id] = []
      return acc
    },
    {}
  )
  const initialValues = _.reduce(
    DEF_FIELDS,
    (acc, field) => {
      acc[field.id] = null
      return acc
    },
    {}
  )

  const [options, setOptions] = useState(initialOptions)
  const [loadingFields, setLoadingFields] = useState([])
  const [selectedValues, setSelectedValues] = useState(initialValues)

  useEffect(() => {
    const initialFetch = async () => {
      const initialFetches = _.filter(DEF_FIELDS, { initialFetch: true })
      return await Promise.all(
        _.map(initialFetches, (field) => fetchOptions(field.id))
      )
    }
    initialFetch()
  }, [])

  const fetchOptions = async (filterType, postBody = {}) => {
    const field = _.find(DEF_FIELDS, { id: filterType })
    if (!field) {
      console.error("Invalid filter type:", filterType)
      return
    }
    const { id, endpoint } = field
    setLoadingField(id)
    try {
      const response = await axios.post(endpoint, {
        user_role: String(userCtx.user_role),
        data_region: userCtx.data_region,
        ...postBody,
      })
      setOptions((prevOptions) => ({
        ...prevOptions,
        [id]: response.data.options,
      }))
      removeLoadingField(id)
    } catch (error) {
      console.error("Error fetching options:", error)
    }
  }

  const setLoadingField = (id) => {
    setLoadingFields((prev) => [...prev, id])
  }

  const removeLoadingField = (id) => {
    setLoadingFields((prev) => prev.filter((field) => field !== id))
  }

  const handleChange = (id, value) => {
    switch (id) {
      case "map_layer": {
        const reset = {
          billing_code_category: null,
        }
        setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        fetchOptions("billing_code_category", {
          geomap_view: selectedValues.geomap_view,
          npi_practice_state: selectedValues.npi_practice_state,
          view_rates_by: selectedValues.view_rates_by,
          map_layer: value,
        })
        break
      }
      case "npi_practice_state": {
        const reset = {
          view_rates_by: null,
          map_layer: null,
          billing_code_category: null,
        }
        setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        fetchOptions("view_rates_by", { region: value })
        break
      }
      case "view_rates_by": {
        const reset = {
          map_layer: null,
          billing_code_category: null,
        }
        setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        fetchOptions("map_layer", {
          region: selectedValues.npi_practice_state,
          view_rates_by: value,
        })
        break
      }
      default:
        setSelectedValues((prevValues) => ({ ...prevValues, [id]: value }))
        break
    }
  }

  const handleClick = () => {
    const paramsString = objectToUrlParams(selectedValues)
    return navigate(
      `/data-room/provider-network-map/provider-network-regional-map?${paramsString}`
    )
  }

  const handleValidation = () => {
    return DEF_FIELDS.every((field) => selectedValues[field.id])
  }

  const isValidate = handleValidation()

  const fields = _.map(DEF_FIELDS, ({ id, label, multiselect }) => ({
    id,
    label,
    multiselect,
  }))

  return (
    <div className={styles.initFilters}>
      {_.map(fields, (field) => {
        const { id, label, multiselect } = field

        return (
          <FilterDropdown
            key={id}
            id={id}
            label={label}
            options={options[id]}
            selectedValue={selectedValues[id]}
            onChange={handleChange}
            fetching={_.includes(loadingFields, id)}
            multiselect={multiselect}
          />
        )
      })}
      <Button
        label="Visualize"
        onClick={handleClick}
        disabled={!isValidate}
        className={isValidate ? "btn-secondary" : "btn-secondary grey"}
      />
    </div>
  )
}

export default FilterContainer
