import React, { useContext } from "react"
import _ from "lodash"
import { PNRMCtx } from "../context/pnrmCtx"

import useTriggerDataLayer from "../../../../../hooks/useTriggerDataLayer"

import { useNavigate } from "react-router-dom"

import SaveReport from "../../../components/SaveReport"

import { Button } from "primereact/button"
import { MultiSelect } from "primereact/multiselect"
import { Dropdown } from "primereact/dropdown"
import { Skeleton } from "primereact/skeleton"

import styles from "../../../../../styles/DataRoom.module.css"

export default function SubFilterPNRM() {
  const context = useContext(PNRMCtx)
  const [triggerDataLayer] = useTriggerDataLayer()
  const navigate = useNavigate()

  const isGeoMapProvider =
    context.selectedValues.geomap_view === "Provider Network Regional Map"

  const isFacilityProvider =
    context.selectedValues.view_rates_by === "facility_provider_type"
  const isFacilityPrimary =
    context.selectedValues.view_rates_by === "facility_system_primary"

  const isMapCounty = context.selectedValues.map_layer === "county_name"
  const isMapCBSA = context.selectedValues.map_layer === "cbsa_msa_name"

  const handleChange = (e) => {
    context.resetPayload()
    const { id, value } = e.target

    const defaultInitValues = {
      geomap_view: context.selectedValues.geomap_view,
      npi_practice_state: context.selectedValues.npi_practice_state,
      view_rates_by: context.selectedValues.view_rates_by,
      map_layer: context.selectedValues.map_layer,
      billing_code_category: context.selectedValues.billing_code_category,
    }

    const resetDefaultValues = {
      billing_class: null,
      billing_code_episode: null,
      carrier_name: null,
      carrier_plan_name: null,
      hco_provider_organization_name: null,
      map_layer_value: null,
      negotiated_type: null,
      procedure_type: null,
      view_rates_by_value: null,
    }

    switch (id) {
      case "billing_code_category": {
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          ...resetDefaultValues,
          [id]: value,
        }))
        context.setLoadingField("view_rates_by_value")
        context.fetchOptions("view_rates_by_value", {
          ...defaultInitValues,
          ...context.selectedValues,
          [id]: value,
        })
        break
      }
      case "view_rates_by_value": {
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          ...resetDefaultValues,
          [id]: value,
        }))
        context.setLoadingField("map_layer_value")
        context.fetchOptions("map_layer_value", {
          ...defaultInitValues,
          ...context.selectedValues,
          [id]: value,
        })
        break
      }
      case "map_layer_value": {
        const { view_rates_by_value, map_layer_value, ...reset } =
          resetDefaultValues
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        context.setLoadingField("billing_code_episode")
        context.fetchOptions("billing_code_episode", {
          ...defaultInitValues,
          ...context.selectedValues,
          [id]: value,
        })
        break
      }
      case "billing_code_episode": {
        const reset = {
          carrier_name: null,
          carrier_plan_name: null,
          hco_provider_organization_name: null,
          procedure_type: null,
          billing_class: null,
          negotiated_type: null,
        }
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        context.setLoadingField("carrier_name")
        context.fetchOptions("carrier_name", {
          ...defaultInitValues,
          ...context.selectedValues,
          [id]: value,
        })
        break
      }
      case "carrier_name": {
        const reset = {
          carrier_name: null,
          carrier_plan_name: null,
          hco_provider_organization_name: null,
          procedure_type: null,
          billing_class: null,
          negotiated_type: null,
        }

        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        context.setLoadingField("carrier_plan_name")
        context.fetchOptions("carrier_plan_name", {
          ...defaultInitValues,
          ...context.selectedValues,
          [id]: value,
        })
        break
      }
      case "carrier_plan_name": {
        const reset = {
          hco_provider_organization_name: null,
          procedure_type: null,
          billing_class: null,
          negotiated_type: null,
        }
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        context.setLoadingField("hco_provider_organization_name")
        context.fetchOptions("hco_provider_organization_name", {
          ...defaultInitValues,
          ...context.selectedValues,
          [id]: value,
        })
        break
      }
      case "hco_provider_organization_name": {
        const reset = {
          procedure_type: null,
          billing_class: null,
          negotiated_type: null,
        }
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        context.setLoadingField("procedure_type")
        context.fetchOptions("procedure_type", {
          ...defaultInitValues,
          ...context.selectedValues,
          [id]: value,
        })
        break
      }
      case "procedure_type": {
        const reset = {
          billing_class: null,
          negotiated_type: null,
        }
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        context.setLoadingField("billing_class")
        context.fetchOptions("billing_class", {
          ...defaultInitValues,
          ...context.selectedValues,
          [id]: value,
        })
        break
      }
      case "billing_class": {
        const reset = {
          negotiated_type: null,
        }
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        context.setLoadingField("negotiated_type")
        context.fetchOptions("negotiated_type", {
          ...defaultInitValues,
          ...context.selectedValues,
          [id]: value,
        })
        break
      }

      default:
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          [id]: value,
        }))
        break
    }
  }

  const HandleDropdown = ({ id, label }) => {
    if (context.loadingFields.includes(id)) {
      return (
        <div>
          <label>{label}</label>
          <Skeleton width="100%" height="43px"></Skeleton>
        </div>
      )
    }
    return (
      <div>
        <label>{label}</label>
        <Dropdown
          options={context.options[id]}
          value={context.selectedValues[id]}
          onChange={handleChange}
          placeholder={label}
          id={id}
          disabled={_.isEmpty(context.options[id])}
        />
      </div>
    )
  }

  const HandleMultiSelect = ({ id, label }) => {
    if (context.loadingFields.includes(id)) {
      return (
        <div>
          <label>{label}</label>
          <Skeleton width="100%" height="3rem"></Skeleton>
        </div>
      )
    }
    const handleLabel = () => {
      switch (id) {
        case "segment_value": {
          if (isFacilityProvider) return "Carrier Name"
          if (isFacilityPrimary) return "Facility Provider System"
          break
        }
        case "segment_value_name": {
          if (isFacilityProvider) return "Carrier Plan Name"
          if (isFacilityPrimary) return "Facility Provider"
          break
        }
        default:
          return label
      }
    }
    return (
      <div>
        <label>{handleLabel()}</label>
        <MultiSelect
          options={context.options[id]}
          value={context.selectedValues[id]}
          onChange={handleChange}
          placeholder={label}
          filter
          showClear
          id={id}
        />
      </div>
    )
  }

  const handleViewRatesByLabel = () => {
    if (isFacilityPrimary) return "Facility Provider System"
    if (isFacilityProvider) return "Facility Provider Type"
  }

  const handleMapLayerLabel = () => {
    if (isMapCounty) return "County Name"
    if (isMapCBSA) return "CBSA Name"
  }

  return (
    <div className={styles.subFiltersWrapper}>
      <div className={styles.subFilters}>
        <HandleMultiSelect
          id="billing_code_category"
          label="Clinical Categories"
        />
      </div>

      <div className={styles.subFilters}>
        <HandleMultiSelect
          id="view_rates_by_value"
          label={handleViewRatesByLabel()}
        />
      </div>

      <div className={styles.subFilters}>
        <HandleMultiSelect id="map_layer_value" label={handleMapLayerLabel()} />
      </div>

      <div className={styles.subFilters}>
        <HandleMultiSelect id="billing_code_episode" label="Episode Name" />
      </div>
      <div className={styles.subFilters}>
        <HandleMultiSelect
          id="carrier_name"
          label="Commercial Insurance Carrier"
        />
        <HandleMultiSelect
          id="carrier_plan_name"
          label="Commercial Insurance Plan Name"
        />
      </div>

      <div className={styles.subFilters}>
        <HandleMultiSelect
          id="hco_provider_organization_name"
          label="Facility Provider"
        />
      </div>

      <div className={styles.subFilters}>
        <HandleDropdown id="procedure_type" label="Procedure Type" />
        <HandleDropdown id="billing_class" label="Billing Class" />
        <HandleDropdown id="negotiated_type" label="Negotiated Rate Type" />
      </div>

      <Button
        label="Visualize"
        onClick={() => {
          const dataLayerBody = {
            report: "Provider Network Map",
            screen: 2,
            ...context.selectedValues,
          }
          triggerDataLayer("data_visualize", dataLayerBody)
          context.handleGenerate()
        }}
        className={context.isValidate ? "btn-secondary" : "btn-secondary grey"}
        disabled={!context.isValidate}
      />
      <Button
        label="Reset"
        onClick={() => navigate("/data-room/")}
        className="reset-btn"
        disabled={!context.isValidate}
      />
      <SaveReport
        isValidate={context.isValidate}
        selectedValues={context.selectedValues}
        reportName={context.reportName}
        projectName={context.projectName}
        saveReport={context.handleSaveReport}
      />
    </div>
  )
}
