import _ from "lodash"

export default function dataGridColFormatter(colList = []) {
  colList.forEach((col) => {
    const { components, dashboardId, column, prefix = null } = col
    const affectedComponent =
      components.find((component) => component.cell === dashboardId) || null

    if (!affectedComponent) {
      console.log("No component")
      return null
    }

    const affectedColumn = affectedComponent?.dataGridOptions?.columns || []

    _.set(affectedComponent, "dataGridOptions", {
      columns: {
        ...affectedColumn,
        [column]: {
          cellFormatter: function () {
            if (prefix === null) {
              return this.value.toLocaleString()
            }
            return `${prefix}${this.value.toLocaleString()}`
          },
        },
      },
    })
  })
}
