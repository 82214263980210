import React, { useState, useEffect, useContext } from "react"
import _ from "lodash"
import axios from "axios"
import { LoaderProgressBar } from "../../../../components/Loaders"

import UserHelpText from "../../components/UserHelpText"

// Ctx
import { UserContext } from "../../../../context/userContext"
import { CICNCCtx } from "./context/cicncCtx"

// PR
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"

// Comps
import Bar from "./highcharts/Bar"

// Filters
import SubFilter from "./components/SubFilter"

// DataRoom URL
import { dataRoomURL } from "../../../../config/cloudFunctionsURL"

import styles from "../../../../styles/DataRoom.module.css"

const baseUrl = dataRoomURL()

const DEF_FIELDS = [
  {
    id: "county_values",
    label: "County Selection",
    endpoint: `${baseUrl}/api/selectors/region`,
    required: true,
    initialFetch: false,
  },
  {
    id: "cbsa_msa_values",
    label: "CBSA-MSA Selection",
    endpoint: `${baseUrl}/api/selectors/region-values`,
    required: true,
    initialFetch: false,
  },
  {
    id: "segment_value",
    label: "Commercial Insurance Carrier",
    endpoint: `${baseUrl}/api/selectors/segment-value-network-bar`,
    required: true,
    initialFetch: false,
  },
  {
    id: "segment_value_name",
    label: "Commercial Insurance Plan Name",
    endpoint: `${baseUrl}/api/selectors/segment-value-name-network-bar`,
    required: true,
    initialFetch: false,
  },
  {
    id: "negotiated_type",
    label: "Negotiated Rate Type",
    endpoint: `${baseUrl}/api/selectors/negotiated-type-network-bar`,
    required: true,
    initialFetch: false,
  },
]

export default function CommercialInsuranceCarrierNetworkComparisons() {
  const userCtx = useContext(UserContext)

  const [loading, setLoading] = useState(false)
  const [fetching, setFetching] = useState(false)

  const [loadingFields, setLoadingFields] = useState([])

  const [payload, setPayload] = useState({})
  const [geomeandata, setGeomeandata] = useState([])

  const [maxOpBillingCodeTypeLabel, setMaxOpBillingCodeTypeLabel] = useState(8)

  const [reportId, setReportId] = useState("")
  const [projectName, setProjectName] = useState("")
  const [reportName, setReportName] = useState("")

  // Params from URL
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  // Default values
  const initialOptions = _.reduce(
    DEF_FIELDS,
    (acc, field) => {
      acc[field.id] = []
      return acc
    },
    {}
  )
  const initialValues = _.reduce(
    DEF_FIELDS,
    (acc, field) => {
      acc[field.id] = null
      return acc
    },
    {}
  )

  // URL Param Values
  const valuesFromUrl = {
    region: urlParams.get("region") ?? "",
    region_value: urlParams.get("region_value") ?? "",
    procedure_type: urlParams.get("procedure_type") ?? "",
    report_id: urlParams.get("report_id") ?? null,
  }

  const [selectedValues, setSelectedValues] = useState({
    ...initialValues,
    ...valuesFromUrl,
    visualization: "networkbar", // Default value....
  })

  // Filters
  const [options, setOptions] = useState({
    ...initialOptions,
    visualization: [{ label: "Bar", value: "bar" }],
  })

  // Init
  useEffect(() => {
    init()
  }, [])

  const getStoredValues = async () => {
    const { report_id } = valuesFromUrl
    setReportId(report_id)
    const url = `${baseUrl}/api/reports/get-full-report`
    const response = await axios.post(url, {
      report_id,
    })
    const reportName = response.data.report_name || ""
    const projectName = response.data.project_name || ""
    setProjectName(projectName)
    setReportName(reportName)
    const values = response.data.values || {}
    return values
  }

  const init = async () => {
    setLoading(true)

    let selectedValuesToUse = {}
    const hadStoredValues = !_.isEmpty(valuesFromUrl.report_id)

    if (hadStoredValues) {
      await fetchInitialFilterValues()
      selectedValuesToUse = await getStoredValues()
      setSelectedValues((prevValues) => ({
        ...prevValues,
        ...selectedValuesToUse,
      }))
    } else {
      selectedValuesToUse = await fetchInitialFilterValues()
    }

    const generateBody = {
      ...selectedValues,
      ...selectedValuesToUse,
    }

    if (selectedValues.region === "state_counties") {
      generateBody.state_values = selectedValues.county_values
    }
    if (selectedValues.region === "state_cbsa_msa") {
      generateBody.state_values = selectedValues.cbsa_msa_values
    }

    return handleGenerate(generateBody)
  }

  const fetchInitialFilterValues = async () => {
    const url = `${baseUrl}/api/selectors/dash-c-filters-init`
    try {
      const response = await axios.post(url, {
        region: selectedValues.region,
        region_value: selectedValues.region_value,
        procedure_type: selectedValues.procedure_type,
        visualization: selectedValues.visualization,
      })
      const { defaultValues, options } = response.data
      setSelectedValues((prevValues) => ({
        ...prevValues,
        ...defaultValues,
      }))
      setOptions((prevOptions) => ({
        ...prevOptions,
        ...options,
      }))
      return defaultValues
    } catch (error) {
      console.error("Error fetching initial filter values", error)
    }
  }

  const fetchOptions = async (filterType, postBody = {}) => {
    const field = _.find(DEF_FIELDS, { id: filterType })
    if (!field) {
      console.error("Invalid filter type:", filterType)
      return
    }
    const { id, endpoint } = field
    setLoadingField(id)
    try {
      const response = await axios.post(endpoint, postBody)
      setOptions((prevOptions) => ({
        ...prevOptions,
        [id]: response.data.options,
      }))

      if (id === "billing_code_type_label") {
        setMaxOpBillingCodeTypeLabel(response.data.max_options)
        setSelectedValues((prevValues) => ({
          ...prevValues,
          ...response.data.defaultValues,
        }))
      }

      removeLoadingField(id)
    } catch (error) {
      console.error("Error fetching options:", error)
    }
  }

  const handleGenerate = async (postBody = {}) => {
    setLoading(true)

    if (_.isEmpty(postBody)) {
      postBody = selectedValues
      if (selectedValues.region === "state_counties") {
        postBody.state_values = selectedValues.county_values
      }
      if (selectedValues.region === "state_cbsa_msa") {
        postBody.state_values = selectedValues.cbsa_msa_values
      }
    }

    try {
      const fetchUrl = `${baseUrl}/api/dashboard/provider-network-bar`
      const response = await axios.post(fetchUrl, postBody)
      setPayload(response.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const setLoadingField = (id) => {
    setLoadingFields((prev) => [...prev, id])
  }

  const removeLoadingField = (id) => {
    setLoadingFields((prev) => prev.filter((field) => field !== id))
  }

  const resetPayload = () => setPayload({})

  const handleSaveReport = async (reportName, projectName, permission) => {
    if (!reportName || !projectName) return

    setLoading(true)

    const currentUrl = new URL(window.location.href)
    const reportPath = currentUrl.pathname + currentUrl.search

    const reportSaveUrl = `${baseUrl}/api/reports/save-report`

    let postBody = {
      user_id: userCtx.id,
      user_role: String(userCtx.user_role),
      values: selectedValues,
      url: reportPath,
      visualization: selectedValues.visualization,
      organization_alias: userCtx.organization_alias,
      report_name: reportName,
      report_id: "",
      project_name: projectName,
      permission,
    }

    if (reportId) {
      postBody.report_id = reportId
    }

    if (selectedValues.segment === "facility_system_primary") {
      postBody.values.segment_value = selectedValues.facility_system_primary
      postBody.values.segment_value_name =
        selectedValues.hco_provider_organization_name
    } else if (selectedValues.segment === "facility_provider_type") {
      postBody.values.segment_value = selectedValues.facility_provider_type
      postBody.values.segment_value_name = selectedValues.carrier_plan_name
    } else if (selectedValues.segment === "carrier_name") {
      postBody.values.segment_value = selectedValues.carrier_name
      postBody.values.segment_value_name = selectedValues.carrier_plan_name
    }

    try {
      const response = await axios.post(reportSaveUrl, postBody)
      setReportId(response.data.doc_id)
      setLoading(false)
    } catch (error) {
      console.error("Error saving report", error)
    }
  }

  const validationPerType = () => {
    const requieredFields = [
      "segment_value",
      "segment_value_name",
      "negotiated_type",
    ]

    return requieredFields.every((field) => !_.isEmpty(selectedValues[field]))
  }

  const ctxValue = {
    fetching,
    fetchOptions,
    options,
    setSelectedValues,
    selectedValues,
    maxOpBillingCodeTypeLabel,
    handleGenerate,
    resetPayload,
    handleSaveReport,
    reportName,
    projectName,
    loadingFields,
    setLoadingField,
    isValidate: validationPerType(),
  }

  const handleGeomeanTable = () => {
    if (_.isEmpty(geomeandata)) return null

    const groupedData = _.chain(geomeandata)
      .groupBy("billing_code_type_label")
      .map((codeData, code) => ({
        billingCodeTypeLabel: code,
        ..._.mapValues(
          _.keyBy(codeData, "carrier_name"),
          "geomean_negotiated_rate"
        ),
      }))
      .value()

    // Flattening the array of objects into an array of rows
    const dataTableArray = _.flatMap(groupedData, (row) => [row])

    const columns = Object.keys(dataTableArray[0]).map((column) => ({
      field: column,
      header: column,
    }))

    return (
      <DataTable value={dataTableArray}>
        {columns.map((col) => (
          <Column key={col.field} field={col.field} header={col.header} />
        ))}
      </DataTable>
    )
  }

  const handleChartPerType = () => {
    if (loading) return <LoaderProgressBar />
    if (_.isEmpty(payload)) return <UserHelpText />
    switch (selectedValues.visualization) {
      case "networkbar":
        return <Bar payload={payload} />
      default:
        return null
    }
  }

  // temp for 'ths_sandbox' organization
  const userOrg = userCtx?.organization_alias
  const handleHideText = () => {
    if (userOrg === "ths_sandbox") {
      return "hidden"
    }
    return ""
  }

  return (
    <div className={`${styles.pageLayout} data-room`}>
      <div>
        <h1>
          Network Selection: Commercial Insurance Carrier Normalized Mean Rates
          for IP/OP
        </h1>
        <p>
          The chart displays a normalized average standard deviation of mean
          negotiated rates for all billing codes classified as Inpatient or
          Outpatient procedures. For reporting purposes, mean billing code rates
          for each represented Commercial Insurance Carrier are standardized to
          a z-score to support comparative data.
        </p>
        <p>
          In general, the chart data aims to assist in carrier plan network
          decisions. The data can be interpreted as: On average, the selected
          carrier plan network reports negotiated billing code rates within a
          selected procedure type that are below (more competitive) or above
          (less competitive) the expected market rate averages. This expected
          market average is indicated by the 0-value line (no deviation from
          average rates) on the chart.
        </p>
        <p>
          Loaded in this tool for modeling is a basket of top billing codes
          representing steady state utilization in a commercial population
          derived from
          <span className={handleHideText()}> MMA Region claims,</span>{" "}
          MarketScan Commercial Claims and Encounters Database, and the
          Healthcare Cost and Utilization Project.
        </p>
      </div>
      <div className={styles.wrapper}>
        <div className="filters">
          <CICNCCtx.Provider value={ctxValue}>
            <SubFilter />
          </CICNCCtx.Provider>
        </div>
        <div className={styles.plot}>
          {handleChartPerType()}
          {handleGeomeanTable()}
        </div>
      </div>
    </div>
  )
}
