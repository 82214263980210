import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import _ from "lodash"

import useTriggerDataLayer from "../../../../../hooks/useTriggerDataLayer"

import { CICNCCtx } from "../context/cicncCtx"

// PR
import { Button } from "primereact/button"
import { MultiSelect } from "primereact/multiselect"
import { Dropdown } from "primereact/dropdown"
import { Skeleton } from "primereact/skeleton"

import SaveReport from "../../../components/SaveReport"

import styles from "../../../../../styles/DataRoom.module.css"

export default function SubFilter() {
  const context = useContext(CICNCCtx)
  const [triggerDataLayer] = useTriggerDataLayer()
  const navigate = useNavigate()

  const handleChange = (e) => {
    context.resetPayload()
    const { id, value } = e.target

    const valuesFromParams = {
      visualization: context.selectedValues.visualization,
      region: context.selectedValues.region,
      region_value: context.selectedValues.region_value,
      procedure_type: context.selectedValues.procedure_type,
    }

    const handleStateValues = () => {
      if (context.selectedValues.region === "state_counties") {
        return context.selectedValues.county_values
      }
      if (context.selectedValues.region === "state_cbsa_msa") {
        return context.selectedValues.cbsa_msa_values
      }
      return null
    }

    switch (id) {
      case "county_values": {
        const reset = {
          segment_value: null,
          segment_value_name: null,
          negotiated_type: null,
        }
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        context.setLoadingField("segment_value")
        context.fetchOptions("segment_value", {
          ...valuesFromParams,
          state_values: value,
        })
        break
      }
      case "cbsa_msa_values": {
        const reset = {
          segment_value: null,
          segment_value_name: null,
          negotiated_type: null,
        }
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        context.setLoadingField("segment_value")
        context.fetchOptions("segment_value", {
          ...valuesFromParams,
          state_values: value,
        })
        break
      }
      case "segment_value": {
        const reset = {
          segment_value_name: null,
          negotiated_type: null,
        }
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        context.setLoadingField("segment_value_name")
        context.fetchOptions("segment_value_name", {
          ...valuesFromParams,
          state_values: handleStateValues(),
          segment_value: value,
        })
        break
      }
      case "segment_value_name": {
        const reset = {
          negotiated_type: null,
        }
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          ...reset,
          [id]: value,
        }))
        context.setLoadingField("negotiated_type")
        context.fetchOptions("negotiated_type", {
          ...valuesFromParams,
          state_values: handleStateValues(),
          segment_value: context.selectedValues.segment_value,
          segment_value_name: value,
        })
        break
      }
      default:
        context.setSelectedValues((prevValues) => ({
          ...prevValues,
          [id]: value,
        }))
        break
    }
  }

  const HandleDropdown = ({ id, label }) => {
    if (context.loadingFields.includes(id)) {
      return (
        <div>
          <label>{label}</label>
          <Skeleton width="100%" height="43px"></Skeleton>
        </div>
      )
    }
    return (
      <div>
        <label>{label}</label>
        <Dropdown
          options={context.options[id]}
          value={context.selectedValues[id]}
          onChange={handleChange}
          placeholder={label}
          id={id}
          disabled={_.isEmpty(context.options[id])}
        />
      </div>
    )
  }

  const HandleMultiSelect = ({ id, label }) => {
    if (context.loadingFields.includes(id)) {
      return (
        <div>
          <label>{label}</label>
          <Skeleton width="100%" height="3rem"></Skeleton>
        </div>
      )
    }
    return (
      <div>
        <label>{label}</label>
        <MultiSelect
          options={context.options[id]}
          value={context.selectedValues[id]}
          onChange={handleChange}
          placeholder={label}
          filter
          showClear
          id={id}
        />
      </div>
    )
  }

  const handleTopFilters = () => {
    const region = context?.selectedValues?.region || null
    switch (region) {
      case "state_counties": {
        return (
          <div className={styles.subFilters}>
            <HandleMultiSelect id="county_values" label="County Selection" />
          </div>
        )
      }
      case "state_cbsa_msa": {
        return (
          <div className={styles.subFilters}>
            <HandleMultiSelect
              id="cbsa_msa_values"
              label="CBSA-MSA Selection"
            />
          </div>
        )
      }
      default:
        return null
    }
  }

  return (
    <div className={styles.subFiltersWrapper}>
      {handleTopFilters()}

      <div className={styles.subFilters}>
        <HandleMultiSelect
          id="segment_value"
          label="Commercial Insurance Carrier"
        />
        <HandleMultiSelect
          id="segment_value_name"
          label="Commercial Insurance Plan Name"
        />
      </div>

      <div className={styles.subFilters}>
        <HandleDropdown id="negotiated_type" label="Negotiated Rate Type" />
      </div>

      <Button
        label="Visualize"
        onClick={() => {
          const dataLayerBody = {
            report: "Commercial Insurance Carrier Network Comparisons",
            screen: 2,
            ...context.selectedValues,
          }
          triggerDataLayer("data_visualize", dataLayerBody)
          context.handleGenerate()
        }}
        className={context.isValidate ? "btn-secondary" : "btn-secondary grey"}
        disabled={!context.isValidate}
      />
      <Button
        label="Reset"
        onClick={() => navigate("/data-room/")}
        className="reset-btn"
        disabled={!context.isValidate}
      />
      <SaveReport
        isValidate={context.isValidate}
        selectedValues={context.selectedValues}
        reportName={context.reportName}
        projectName={context.projectName}
        saveReport={context.handleSaveReport}
      />
    </div>
  )
}
