import React, { useState, useEffect, useContext } from "react"
import _ from "lodash"
import axios from "axios"

// Loaders
import { LoaderProgressBar } from "../../../../components/Loaders"

// Ctx
import { UserContext } from "../../../../context/userContext"
import { PNRMCtx } from "./context/pnrmCtx"

// Comps
import UserHelpText from "../../components/UserHelpText"
import Map from "./highcharts/Map"

import DataTableStats from "./components/DataTableStats"
import DataTableRank from "./components/DataTableRank"
import SubFiltersPNRM from "./components/SubFiltersPNRM"

// DataRoom URL
import { dataRoomURL } from "../../../../config/cloudFunctionsURL"

import styles from "../../../../styles/DataRoom.module.css"

const baseUrl = dataRoomURL()

const DEF_FIELDS = [
  {
    id: "billing_code_category",
    label: "Episode Name",
    endpoint: `${baseUrl}/api/selectors/billing-code-category`,
    required: true,
    initialFetch: false,
    fetchBody: [
      "geomap_view",
      "npi_practice_state",
      "view_rates_by",
      "map_layer",
    ],
  },
  {
    id: "view_rates_by_value",
    label: "Episode Name",
    endpoint: `${baseUrl}/api/selectors/view-rates-by-value`,
    required: true,
    initialFetch: false,
  },
  {
    id: "map_layer_value",
    label: "Map Layer",
    endpoint: `${baseUrl}/api/selectors/map-layer-value`,
    initialFetch: false,
  },
  {
    id: "billing_code_episode",
    label: "Episode Name",
    endpoint: `${baseUrl}/api/selectors/episode-name-map`,
    required: true,
    initialFetch: false,
  },
  {
    id: "carrier_name",
    label: "Commercial Insurance Carrier",
    endpoint: `${baseUrl}/api/selectors/carrier-name-map`,
    required: true,
    initialFetch: false,
  },
  {
    id: "carrier_plan_name",
    label: "Commercial Insurance Plan Name",
    endpoint: `${baseUrl}/api/selectors/carrier-plan-name-map`,
    required: true,
    initialFetch: false,
  },
  {
    id: "hco_provider_organization_name",
    label: "Facility Provider",
    endpoint: `${baseUrl}/api/selectors/hco-provider-organization-name-map`,
    required: true,
    initialFetch: false,
  },
  {
    id: "billing_code_episode",
    label: "Clinical Categories",
    endpoint: `${baseUrl}/api/selectors/billing-code-category-option-map`,
    required: true,
    initialFetch: false,
  },
  {
    id: "procedure_type",
    label: "Procedure Type",
    endpoint: `${baseUrl}/api/selectors/procedure-type-map`,
    required: true,
    initialFetch: false,
  },
  {
    id: "billing_class",
    label: "Billing Class",
    endpoint: `${baseUrl}/api/selectors/billing-class-map`,
    required: true,
    initialFetch: false,
  },
  {
    id: "negotiated_type",
    label: "Negotiated Rate Type",
    endpoint: `${baseUrl}/api/selectors/negotiated-type-map`,
    required: true,
    initialFetch: false,
  },
]

export default function ProviderNetworkCodeMap() {
  const userCtx = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const [fetching, setFetching] = useState(false)

  const [loadingFields, setLoadingFields] = useState([])

  const [payload, setPayload] = useState({})
  const [geomeandata, setGeomeandata] = useState([])
  const [rankdata, setRankdata] = useState([])

  const [reportId, setReportId] = useState("")
  const [projectName, setProjectName] = useState("")
  const [reportName, setReportName] = useState("")

  // Params from URL
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  const valuesFromUrl = {
    geomap_view: urlParams.get("geomap_view") ?? "",
    npi_practice_state: urlParams.get("npi_practice_state") ?? "",
    view_rates_by: urlParams.get("view_rates_by") ?? "",
    map_layer: urlParams.get("map_layer") ?? "",
    billing_code_category:
      _.split(urlParams.get("billing_code_category"), ",") ?? "",
    reportId: urlParams.get("report_id") ?? null,
  }

  // State default values
  const initialOptions = _.reduce(
    DEF_FIELDS,
    (acc, field) => {
      acc[field.id] = []
      return acc
    },
    {}
  )
  const initialValues = _.reduce(
    DEF_FIELDS,
    (acc, field) => {
      acc[field.id] = null
      return acc
    },
    {}
  )

  const [selectedValues, setSelectedValues] = useState({
    ...initialValues,
    ...valuesFromUrl,
    visualization: "geomap", // Default value....
  })

  const isNegocitated =
    selectedValues.geomap_view === "Negotiated Rate Averages Regional Map"

  const isProvider =
    selectedValues.geomap_view === "Provider Network Regional Map"

  // Filters
  const [options, setOptions] = useState(initialOptions)

  useEffect(() => {
    init()
    fetchInitialOptions()
  }, [])

  const getStoredValues = async () => {
    const { reportId } = valuesFromUrl
    setReportId(reportId)
    const url = `${baseUrl}/api/reports/get-full-report`
    const response = await axios.post(url, {
      report_id: reportId,
    })
    const reportName = response.data.report_name || ""
    const projectName = response.data.project_name || ""
    setProjectName(projectName)
    setReportName(reportName)
    const values = response.data.values || {}
    return values
  }

  const fetchInitialOptions = async () => {
    const promises = DEF_FIELDS.map((field) => {
      if (field.initialFetch) {
        const postBody = _.pick(selectedValues, field.fetchBody)
        return fetchOptions(field.id, postBody)
      }
      return Promise.resolve()
    })
    await Promise.all(promises)
  }

  const init = async () => {
    setLoading(true)

    let selectedValuesToUse = {}
    const hadStoredValues = !_.isEmpty(valuesFromUrl.reportId)

    if (hadStoredValues) {
      await fetchInitialFilterValues()
      selectedValuesToUse = await getStoredValues()
      setSelectedValues((prevValues) => ({
        ...prevValues,
        ...selectedValuesToUse,
      }))
      // should return??
    } else {
      selectedValuesToUse = await fetchInitialFilterValues()
    }

    const postBody = {
      ...valuesFromUrl,
      ...selectedValuesToUse,
    }

    return handleGenerate(postBody)
  }

  const fetchInitialFilterValues = async () => {
    const url = `${baseUrl}/api/selectors/dash-b-filters-init`
    try {
      const response = await axios.post(url, {
        geomap_view: selectedValues.geomap_view,
        npi_practice_state: selectedValues.npi_practice_state,
        view_rates_by: selectedValues.view_rates_by,
        map_layer: selectedValues.map_layer,
        billing_code_category: selectedValues.billing_code_category,
        visualization: selectedValues.visualization,
      })
      const { defaultValues, options } = response.data
      setSelectedValues((prevValues) => ({
        ...prevValues,
        ...defaultValues,
      }))
      setOptions((prevOptions) => ({
        ...prevOptions,
        ...options,
      }))
      return defaultValues
    } catch (error) {
      console.error("Error fetching initial filter values", error)
    }
  }

  const fetchOptions = async (filterType, postBody = {}) => {
    const field = _.find(DEF_FIELDS, { id: filterType })
    if (!field) {
      console.error("Invalid filter type:", filterType)
      return
    }
    const { id, endpoint } = field
    setLoadingField(id)
    try {
      const response = await axios.post(endpoint, postBody)
      setOptions((prevOptions) => ({
        ...prevOptions,
        [filterType]: response.data.options,
      }))
      setFetching(false)
      removeLoadingField(filterType)
    } catch (error) {
      console.error("Error fetching options:", filterType, error)
      setFetching(false)
    }
  }

  const handleGenerate = async (postBody = {}) => {
    setLoading(true)
    try {
      const fetchUrl = `${baseUrl}/api/dashboard/provider-network-regional-map`
      const statsUrl = `${baseUrl}/api/dashboard/provider-network-regional-map-detail`

      if (_.isEmpty(postBody)) {
        postBody = selectedValues
      }

      const [payload, stats] = await Promise.all([
        axios.post(fetchUrl, postBody),
        axios.post(statsUrl, postBody),
      ])

      setPayload(payload.data)

      setGeomeandata(stats.data.data)
      setRankdata(stats.data.data_rank)

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const setLoadingField = (id) => {
    setLoadingFields((prev) => [...prev, id])
  }

  const removeLoadingField = (id) => {
    setLoadingFields((prev) => prev.filter((field) => field !== id))
  }

  const validationPerType = () => {
    const requieredFields = [
      "billing_class",
      "billing_code_category",
      "billing_code_episode",
      "carrier_name",
      "carrier_plan_name",
      "hco_provider_organization_name",
      "map_layer_value",
      "negotiated_type",
      "procedure_type",
      "view_rates_by_value",
    ]
    return requieredFields.every((field) => selectedValues[field])
  }

  const resetPayload = () => setPayload({})

  const handleSaveReport = async (reportName, projectName, permission) => {
    if (!reportName || !projectName) return

    setLoading(true)

    const currentUrl = new URL(window.location.href)
    const reportPath = currentUrl.pathname + currentUrl.search

    const reportSaveUrl = `${baseUrl}/api/reports/save-report`

    let postBody = {
      user_id: userCtx.id,
      values: selectedValues,
      url: reportPath,
      visualization: selectedValues.visualization,
      organization_alias: userCtx.organization_alias,
      report_name: reportName,
      report_id: "",
      project_name: projectName,
      permission,
    }

    if (reportId) {
      postBody.report_id = reportId
    }

    try {
      const response = await axios.post(reportSaveUrl, postBody)
      setReportId(response.data.doc_id)
      setLoading(false)
    } catch (error) {
      console.error("Error saving report", error)
    }
  }

  const ctxValue = {
    fetching,
    fetchOptions,
    options,
    setSelectedValues,
    selectedValues,
    handleGenerate,
    resetPayload,
    handleSaveReport,
    reportName,
    projectName,
    loadingFields,
    setLoadingField,
    isValidate: validationPerType(),
  }

  const handleWeightProp = () => {
    switch (selectedValues.geomap_view) {
      case "Provider Network Regional Map":
        return "number_providers"
      case "Negotiated Rate Averages Regional Map":
        return "mean"
      default:
        return ""
    }
  }

  const handleMapTitle = () => {
    const bundleList = selectedValues.billing_code_category.join(", ") || null
    switch (selectedValues.geomap_view) {
      case "Provider Network Regional Map":
        return `Facility Provider Network and Carrier Ranking Map, ${bundleList}`
      case "Negotiated Rate Averages Regional Map":
        return `Categorical Range and Average for Negotiated Rates, ${bundleList}`
      default:
        return ""
    }
  }

  const handleMapSubTitle = () => {
    switch (selectedValues.map_layer) {
      case "county_name":
        return `Visualization by Counties`
      case "cbsa_msa_name":
        return `Visualization by CBSA-MSA`
      default:
        return ""
    }
  }

  const handleGeoNameLabel = () => {
    switch (selectedValues.map_layer) {
      case "county_name":
        return "County"
      case "cbsa_msa_name":
        return "CBSA-MSA"
      default:
        return null
    }
  }

  const handleRenderMap = () => {
    if (loading) return <LoaderProgressBar />
    if (_.isEmpty(payload)) return <UserHelpText />

    const showMap = !_.isEmpty(payload) && !_.isEmpty(geomeandata)
    if (!showMap) return <div>No results found.</div>

    return (
      <div className={styles.map}>
        <Map
          region={selectedValues.npi_practice_state}
          data={payload.data}
          min={payload.min}
          max={payload.max}
          geo_boundary={selectedValues.map_layer}
          weight_key={handleWeightProp()}
          title={handleMapTitle()}
          subTitle={handleMapSubTitle()}
          isNegocitated={isNegocitated}
          isProvider={isProvider}
        />
        <div className="grid flex-nowrap gap-3 mt-5">
          <DataTableRank
            data={rankdata}
            isNegocitated={isNegocitated}
            isProvider={isProvider}
            mapLayer={selectedValues.map_layer}
          />
          <DataTableStats
            data={geomeandata}
            geoNameLabel={handleGeoNameLabel}
            isNegocitated={isNegocitated}
            isProvider={isProvider}
          />
        </div>
      </div>
    )
  }

  const handleH1Title = () => {
    switch (selectedValues.geomap_view) {
      case "Provider Network Regional Map":
        return "Provider Network and Carrier Clinical Category Ranking Map"
      case "Negotiated Rate Averages Regional Map":
        return "Negotiated Rate Averages Regional Map"
      default:
        return ""
    }
  }

  // temp for 'ths_sandbox' organization
  const userOrg = userCtx?.organization_alias
  const handleHideText = () => {
    if (userOrg === "ths_sandbox") {
      return "hidden"
    }
    return ""
  }

  const handleDescription = () => {
    switch (selectedValues.geomap_view) {
      case "Provider Network Regional Map":
        return (
          <div>
            <p>
              The Provider Network Regional Map displays a series of heath care
              facility provider system and type characteristics as reported
              within commercial insurance carrier network directories and
              references.
            </p>
            <p>
              The map can be viewed by County or Core-Based Statistical Areas
              (CBSA) regions for provider density by selected clinical
              categories. Provider density (number of providers in the network)
              is indicated by map color scale with greater density showing in a
              darker color shade.
            </p>
            <p>
              For each selected Clinical Category, geographic regions where
              commercial insurance carriers have negotiated rates with providers
              are highlighted. Select a County or CBSA region to view provider
              network details and competitive ranking of carrier plans based on
              market rate average positions for category billing codes. Loaded
              in this tool for modeling is a basket of top billing codes
              representing steady state utilization in a commercial population
              derived from{" "}
              <span className={handleHideText()}>MMA Region claims, </span>
              MarketScan Commercial Claims and Encounters Database, and the
              Healthcare Cost and Utilization Project.
            </p>
          </div>
        )
      case "Negotiated Rate Averages Regional Map":
        return (
          <p>
            The Negotiated Rate Averages Regional Map displays a series of
            negotiated rate averages by selected clinical categories and
            procedure types. The map can be viewed by County or Core-Based
            Statistical Areas (CBSA) boundaries for provider density by selected
            clinical categories.
          </p>
        )
      default:
        return
    }
  }

  return (
    <div className={`${styles.pageLayout} data-room`}>
      <h1>{handleH1Title()}</h1>
      {handleDescription()}
      <div className={styles.wrapper}>
        <div className="filters">
          <PNRMCtx.Provider value={ctxValue}>
            <SubFiltersPNRM />
          </PNRMCtx.Provider>
        </div>
        {handleRenderMap()}
      </div>
    </div>
  )
}
