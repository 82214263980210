import React, { useState, useEffect, useContext } from "react"
import _ from "lodash"
import axios from "axios"
import { LoaderProgressBar } from "../../../../components/Loaders"

// Ctx
import { UserContext } from "../../../../context/userContext"
import { CINRCtx } from "./context/cinrCtx"

// PR
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"

// Comps
import UserHelpText from "../../components/UserHelpText"
import Bar from "./highcharts/Bar"
import GroupedBar from "./highcharts/GroupedBar"
import Density from "./highcharts/Density"

// Filters
import SubFilterBCCategories from "./components/SubFilterBCCategories"

// DataRoom URL
import { dataRoomURL } from "../../../../config/cloudFunctionsURL"

import styles from "../../../../styles/DataRoom.module.css"

const baseUrl = dataRoomURL()

const DEF_FIELDS = [
  {
    id: "billing_code_category",
    label: "Billing Code Category",
    endpoint: `${baseUrl}/api/selectors/code-bundle`,
    required: true,
    initialFetch: false,
  },
  {
    id: "billing_code_episode",
    label: "Billing Code Episode",
    endpoint: `${baseUrl}/api/selectors/episode-name`, // Should use new endpoint...
    required: true,
    initialFetch: true,
    fetchBody: [
      "billing_code_category",
      "region",
      "region_value",
      "segment",
      "visualization",
    ],
  },
  {
    id: "region",
    label: "Region",
    endpoint: `${baseUrl}/api/selectors/region`,
    required: true,
    initialFetch: false,
    fetchBody: [],
  },
  {
    id: "region_value",
    label: "Region Value",
    endpoint: `${baseUrl}/api/selectors/region-values`,
    required: true,
    initialFetch: false,
    fetchBody: [],
  },
  {
    id: "carrier_name",
    label: "Carrier Plan Name",
    endpoint: `${baseUrl}/api/selectors/segment-value`,
    required: true,
    initialFetch: false,
  },
  {
    id: "carrier_plan_name",
    label: "Carrier Plan Name",
    endpoint: `${baseUrl}/api/selectors/segment-value-name`,
    required: true,
    initialFetch: false,
  },
  {
    id: "facility_system_primary",
    label: "Facility System Primary",
    endpoint: `${baseUrl}/api/selectors/segment-value`,
    required: true,
    initialFetch: false,
  },
  {
    id: "facility_provider_type",
    label: "Facility System Primary",
    endpoint: `${baseUrl}/api/selectors/segment-value`,
    required: true,
    initialFetch: false,
  },
  {
    id: "hco_provider_organization_name",
    label: "Facility Provider Name",
    endpoint: `${baseUrl}/api/selectors/segment-value-name`,
    required: true,
    initialFetch: false,
  },
  {
    id: "procedure_type",
    label: "Procedure Type",
    endpoint: `${baseUrl}/api/selectors/ip-op`,
    required: true,
    initialFetch: false,
  },
  {
    id: "negotiated_type",
    label: "Negotiated Type",
    endpoint: `${baseUrl}/api/selectors/negotiated-type`,
    required: true,
    initialFetch: false,
  },
  {
    id: "billing_code_type_label",
    label: "Billing Code Type Label",
    endpoint: `${baseUrl}/api/selectors/billing-codes`,
    required: true,
    initialFetch: false,
    fetchBody: [
      "region",
      "region_value",
      "segment",
      "billing_code_category",
      "visualization",
    ],
  },
]

export default function CommercialInsuranceNegotiatedRates() {
  const userCtx = useContext(UserContext)

  const [loading, setLoading] = useState(false)
  const [fetching, setFetching] = useState(false)

  const [loadingFields, setLoadingFields] = useState([])

  const [payload, setPayload] = useState({})
  const [geomeandata, setGeomeandata] = useState([])

  const [maxOpBillingCodeTypeLabel, setMaxOpBillingCodeTypeLabel] = useState(8)

  const [reportId, setReportId] = useState("")
  const [projectName, setProjectName] = useState("")
  const [reportName, setReportName] = useState("")

  // Params from URL
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  // Default values
  const initialOptions = _.reduce(
    DEF_FIELDS,
    (acc, field) => {
      acc[field.id] = []
      return acc
    },
    {}
  )
  const initialValues = _.reduce(
    DEF_FIELDS,
    (acc, field) => {
      acc[field.id] = null
      return acc
    },
    {}
  )

  // URL Param Values
  const valuesFromUrl = {
    region: urlParams.get("region") ?? "",
    region_value: urlParams.get("region_value") ?? "",
    segment: urlParams.get("segment") ?? "",
    billing_code_category: urlParams.get("billing_code_category") ?? "",
    billing_code_category_value:
      urlParams.get("billing_code_category_value") ?? "",
    reportId: urlParams.get("report_id") ?? null,
  }

  const [selectedValues, setSelectedValues] = useState({
    ...initialValues,
    ...valuesFromUrl,
    visualization: "bar", // Default value....
  })

  // Filters
  const [options, setOptions] = useState({
    ...initialOptions,
    visualization: [
      { label: "Bar", value: "bar" },
      { label: "Grouped Bar", value: "grouped-bar" },
    ],
  })

  // Init
  useEffect(() => {
    init()
  }, [])

  const getStoredValues = async () => {
    const { reportId } = valuesFromUrl
    setReportId(reportId)
    const url = `${baseUrl}/api/reports/get-full-report`
    const response = await axios.post(url, {
      report_id: reportId,
    })
    const reportName = response.data.report_name || ""
    const projectName = response.data.project_name || ""
    setProjectName(projectName)
    setReportName(reportName)
    const values = response.data.values || {}
    return values
  }

  const init = async () => {
    setLoading(true)

    let selectedValuesToUse = {}
    const hadStoredValues = !_.isEmpty(valuesFromUrl.reportId)

    if (hadStoredValues) {
      await fetchInitialFilterValues()
      selectedValuesToUse = await getStoredValues()
      setSelectedValues((prevValues) => ({
        ...prevValues,
        ...selectedValuesToUse,
      }))
    } else {
      selectedValuesToUse = await fetchInitialFilterValues()
    }

    const generateBody = {
      ...selectedValues,
      ...selectedValuesToUse,
    }

    const { carrier_name, carrier_plan_name, ...finalBody } = generateBody

    if (selectedValues.segment === "facility_system_primary") {
      finalBody.segment_value = selectedValuesToUse.facility_system_primary
      finalBody.segment_value_name =
        selectedValuesToUse.hco_provider_organization_name
    }

    if (selectedValues.segment === "facility_provider_type") {
      finalBody.segment_value = selectedValuesToUse.facility_provider_type
      finalBody.segment_value_name = carrier_plan_name
    }

    if (selectedValues.segment === "carrier_name") {
      finalBody.segment_value = carrier_name
      finalBody.segment_value_name = carrier_plan_name
    }

    return handleGenerate(finalBody)
  }

  const fetchInitialFilterValues = async () => {
    const url = `${baseUrl}/api/selectors/dash-a-filters-init`
    try {
      const response = await axios.post(url, {
        region: selectedValues.region,
        region_value: selectedValues.region_value,
        segment: selectedValues.segment,
        billing_code_category: selectedValues.billing_code_category,
        billing_code_category_value: selectedValues.billing_code_category_value,
        visualization: selectedValues.visualization,
        user_role: userCtx.user_role.toString(),
        data_region: userCtx.data_region,
      })
      const { defaultValues, options } = response.data
      setSelectedValues((prevValues) => ({
        ...prevValues,
        ...defaultValues,
      }))
      setOptions((prevOptions) => ({
        ...prevOptions,
        ...options,
      }))
      return defaultValues
    } catch (error) {
      console.error("Error fetching initial filter values", error)
    }
  }

  const fetchOptions = async (filterType, postBody = {}) => {
    const field = _.find(DEF_FIELDS, { id: filterType })
    if (!field) {
      console.error("Invalid filter type:", filterType)
      return
    }
    const { id, endpoint } = field
    setLoadingField(id)
    try {
      const response = await axios.post(endpoint, postBody)
      setOptions((prevOptions) => ({
        ...prevOptions,
        [id]: response.data.options,
      }))

      if (id === "billing_code_type_label") {
        setMaxOpBillingCodeTypeLabel(response.data.max_options)
        setSelectedValues((prevValues) => ({
          ...prevValues,
          ...response.data.defaultValues,
        }))
      }

      removeLoadingField(id)
    } catch (error) {
      console.error("Error fetching options:", error)
    }
  }

  const handleGenerate = async (postBody = {}) => {
    setLoading(true)
    try {
      const fetchUrl = `${baseUrl}/api/dashboard/commercial-insurance-negotiated-rates`
      const statsUrl = `${baseUrl}/api/dashboard/commercial-insurance-negotiated-rates-geomean`

      // ...........CHECK THIS: redundant to :225...........
      if (_.isEmpty(postBody)) {
        if (selectedValues.segment === "facility_system_primary") {
          postBody = {
            ...selectedValues,
            segment_value: selectedValues.facility_system_primary,
            segment_value_name: selectedValues.hco_provider_organization_name,
          }
        } else if (selectedValues.segment === "carrier_name") {
          postBody = {
            ...selectedValues,
            segment_value: selectedValues.carrier_name,
            segment_value_name: selectedValues.carrier_plan_name,
          }
        } else if (selectedValues.segment === "facility_provider_type") {
          postBody = {
            ...selectedValues,
            segment_value: selectedValues.facility_provider_type,
            segment_value_name: selectedValues.carrier_plan_name,
          }
        }
      }

      if (selectedValues.visualization === "density") {
        const response = await Promise.all([
          axios.post(fetchUrl, postBody),
          axios.post(statsUrl, postBody),
        ])
        setPayload(response[0].data)
        setGeomeandata(response[1].data.geomeandata)
        setLoading(false)
        return
      }

      const response = await axios.post(fetchUrl, postBody)

      setPayload(response.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const setLoadingField = (id) => {
    setLoadingFields((prev) => [...prev, id])
  }

  const removeLoadingField = (id) => {
    setLoadingFields((prev) => prev.filter((field) => field !== id))
  }

  const resetPayload = () => setPayload({})

  const handleSaveReport = async (reportName, projectName, permission) => {
    if (!reportName || !projectName) return

    setLoading(true)

    const currentUrl = new URL(window.location.href)
    const reportPath = currentUrl.pathname + currentUrl.search

    const reportSaveUrl = `${baseUrl}/api/reports/save-report`

    let postBody = {
      user_id: userCtx.id,
      user_role: String(userCtx.user_role),
      data_region: userCtx.data_region,
      values: selectedValues,
      url: reportPath,
      visualization: selectedValues.visualization,
      organization_alias: userCtx.organization_alias,
      report_name: reportName,
      report_id: "",
      project_name: projectName,
      permission,
    }

    if (reportId) {
      postBody.report_id = reportId
    }

    if (selectedValues.segment === "facility_system_primary") {
      postBody.values.segment_value = selectedValues.facility_system_primary
      postBody.values.segment_value_name =
        selectedValues.hco_provider_organization_name
    } else if (selectedValues.segment === "facility_provider_type") {
      postBody.values.segment_value = selectedValues.facility_provider_type
      postBody.values.segment_value_name = selectedValues.carrier_plan_name
    } else if (selectedValues.segment === "carrier_name") {
      postBody.values.segment_value = selectedValues.carrier_name
      postBody.values.segment_value_name = selectedValues.carrier_plan_name
    }

    try {
      const response = await axios.post(reportSaveUrl, postBody)
      setReportId(response.data.doc_id)
      setLoading(false)
    } catch (error) {
      console.error("Error saving report", error)
    }
  }

  const validationPerType = () => {
    let requieredFieldsFacility = [
      "region",
      "region_value",
      "billing_code_episode",
      "facility_system_primary",
      "procedure_type",
      "negotiated_type",
    ]

    let requieredFieldsCarrier = [
      "region",
      "region_value",
      "billing_code_episode",
      "carrier_name",
      "carrier_plan_name",
      "procedure_type",
      "negotiated_type",
    ]

    let requieredFieldsFacilityProvider = [
      "region",
      "region_value",
      "billing_code_episode",
      "facility_provider_type",
      "carrier_plan_name",
      "procedure_type",
      "negotiated_type",
    ]

    if (selectedValues.segment === "facility_system_primary") {
      return requieredFieldsFacility.every(
        (field) => !_.isEmpty(selectedValues[field])
      )
    }

    if (selectedValues.segment === "carrier_name") {
      if (selectedValues.visualization === "grouped-bar") {
        requieredFieldsCarrier.push("billing_code_type_label")
      }
      return requieredFieldsCarrier.every(
        (field) => !_.isEmpty(selectedValues[field])
      )
    }

    if (selectedValues.segment === "facility_provider_type") {
      return requieredFieldsFacilityProvider.every(
        (field) => !_.isEmpty(selectedValues[field])
      )
    }

    return false
  }

  const ctxValue = {
    fetching,
    fetchOptions,
    options,
    setSelectedValues,
    selectedValues,
    maxOpBillingCodeTypeLabel,
    handleGenerate,
    resetPayload,
    handleSaveReport,
    reportName,
    projectName,
    loadingFields,
    setLoadingField,
    isValidate: validationPerType(),
  }

  const handleGeomeanTable = () => {
    if (_.isEmpty(geomeandata)) return null

    const groupedData = _.chain(geomeandata)
      .groupBy("billing_code_type_label")
      .map((codeData, code) => ({
        billingCodeTypeLabel: code,
        ..._.mapValues(
          _.keyBy(codeData, "carrier_name"),
          "geomean_negotiated_rate"
        ),
      }))
      .value()

    // Flattening the array of objects into an array of rows
    const dataTableArray = _.flatMap(groupedData, (row) => [row])

    const columns = Object.keys(dataTableArray[0]).map((column) => ({
      field: column,
      header: column,
    }))

    return (
      <DataTable value={dataTableArray}>
        {columns.map((col) => (
          <Column key={col.field} field={col.field} header={col.header} />
        ))}
      </DataTable>
    )
  }

  const handleChartPerType = () => {
    if (loading) return <LoaderProgressBar />
    if (_.isEmpty(payload)) return <UserHelpText />
    switch (selectedValues.visualization) {
      case "bar":
        return <Bar payload={payload} />
      case "grouped-bar":
        return <GroupedBar payload={payload} />
      case "density":
        return <Density payload={payload} />
      default:
        return null
    }
  }

  // temp for 'ths_sandbox' organization
  const userOrg = userCtx?.organization_alias
  const handleHideText = () => {
    if (userOrg === "ths_sandbox") {
      return "hidden"
    }
    return ""
  }

  function HandleHeader() {
    if (selectedValues.visualization === "bar") {
      return (
        <div>
          <h1>
            Analytic Dashboard: Market Rate Competitiveness in Clinical
            Categories
          </h1>
          <p>
            The dashboard displays a normalized average standard deviation of
            the mean negotiated rates for all billing codes in a selected
            clinical categories and episodes. To arrive at this categorical
            deviation, mean billing code rates for Commercial Insurance
            Carriers, Facility Provider Systems, or Facility Provider Types are
            standardized to a z-score to support comparative data.
          </p>
          <p>
            In general, the chart data aims to assist in market steerage
            decisions. The data can be interpreted as: On average, the selected
            carrier, facility system, or provider type reports negotiated
            billing code rates within a selected clinical category that are
            below (more competitive) or above (less competitive) the expected
            market rate averages. This expected market average is indicated by
            the 0-value line (no standard deviations) on the chart.
          </p>
          <p>
            Loaded in this tool for modeling is a basket of top billing codes
            representing steady state utilization in a commercial population
            derived from{" "}
            <span className={handleHideText()}>MMA Region claims, </span>
            MarketScan Commercial Claims and Encounters Database and the
            Healthcare Cost and Utilization Project.
          </p>
        </div>
      )
    } else if (selectedValues.visualization === "grouped-bar") {
      return (
        <div>
          <h1>
            Analytic Dashboard: Descriptive Billing Code Negotiated Rates within
            Clinical Categories
          </h1>
          <p>
            The dashboard provides standard descriptive statistics for
            negotiated rates from regional commercial insurance carriers. Rates
            are specific to medical and procedural billing codes within selected
            clinical categories. Loaded in this tool for modeling is a basket of
            top billing codes representing steady state utilization in a
            commercial population derived from
            <span className={handleHideText()}> MMA Region claims,</span>{" "}
            MarketScan Commercial Claims and Encounters Database, and the
            Healthcare Cost and Utilization Project. These descriptive values
            include the minimum negotiated rate for regional providers, the mean
            (average) billing code rate, the maximum billing code rate, and the
            number of distinct rates reported by respective carriers for each
            categorical billing code.
          </p>
          <p>
            Select the descriptive type displayed on the chart for grouped rate
            values by commercial insurance carrier, facility provider system, or
            facility provider type.
          </p>
        </div>
      )
    }
  }

  return (
    <div className={`${styles.pageLayout} data-room`}>
      <HandleHeader />

      <div className={styles.wrapper}>
        <div className="filters">
          <CINRCtx.Provider value={ctxValue}>
            <SubFilterBCCategories />
          </CINRCtx.Provider>
        </div>
        <div className={styles.plot}>
          {handleChartPerType()}
          {handleGeomeanTable()}
        </div>
      </div>
    </div>
  )
}
